import { createLogger, createStore } from "vuex";
import users from "./modules/users";
import device from "./modules/device";
import { ownerFilter, parseQuery, quickFilters } from "@/lib/search";
import { queryTenants, createTenant, inviteUser, updateUser, removeUser, removeUserTenant } from "@/api/tenants";
import auth from "@/store/modules/auth";

const debug = process.env.NODE_ENV !== "production";

const notificationTimeout = 10; //seconds

const state = () => ({
  searchQueryData: {},
  searchQuery: "",
  quickFilters,
  ownerFilters: [],
  notification: null,
  tenants: null,
  loading: false,
});

const actions = {
  async search({ commit, state }, searchQuery) {
    commit("setSearchQuery", searchQuery);
    commit("setSearchQueryData", parseQuery(searchQuery, state.tenants));
  },

  async addFilterToSearch({ commit, state }, filter) {
    if (filter === "") {
      return actions.search({ commit, state }, "");
    }
    if (state.searchQuery) {
      const filterType = filter.split(":")[0];
      const queryTerms = state.searchQuery.split(" ");

      if (queryTerms.find((term) => term.startsWith(filterType))) {
        filter = queryTerms.map((term) => (term.startsWith(filterType) ? filter : term)).join(" ");
      } else {
        filter = queryTerms.concat([filter]).join(" ");
      }
    }
    return actions.search({ commit, state }, filter);
  },

  async setQuickFilterStats({ commit }, quickFilterWithStats) {
    commit("setQuickFilter", quickFilterWithStats);
  },

  async loadTenants({ commit, state }, refresh = false) {
    if (!state.tenants || refresh) {
      commit("setLoading", true);

      let hasNextPage = true;
      let nextPageToken = null;
      let tenants = [];
      while (hasNextPage) {
        const result = await queryTenants(nextPageToken);
        hasNextPage = result.hasNextPage;
        nextPageToken = result.nextPageToken;
        tenants = tenants.concat(result.tenants);
        if (tenants.length > 2) tenants.sort((a, b) => a.name.localeCompare(b.name));
        commit("setTenants", tenants);
        commit("setLoading", false);
      }
    }
  },

  async createOwnerFilters({ commit, dispatch, state }) {
    await dispatch("loadTenants");
    commit(
      "setOwnerFilters",
      state.tenants.map((tenant) => ownerFilter(tenant))
    );
  },

  async showNotification({ commit }, notification) {
    commit("clearNotification", notification);

    notification.timeoutId = setTimeout(() => {
      commit("clearNotification");
    }, notificationTimeout * 1000);

    commit("showNotification", notification);
  },

  async createTenant({ dispatch }, tenantName) {
    const ret = await createTenant(tenantName);
    dispatch("loadTenants", true);
    return ret;
  },

  async inviteTenantUser({ dispatch }, data) {
    const ret = await inviteUser(data);
    dispatch("loadTenants", true);
    return ret;
  },

  async inviteUsersForTenant({ dispatch }, data) {
    data.emails.forEach(async (user) => {
      await inviteUser({
        email: user.mail,
        tenantsRoles: [
          {
            tenantId: data.tenantId,
            role: user.role,
          },
        ],
      });
    });
    dispatch("loadTenants");
  },

  async updateTenantUser({ dispatch }, data) {
    const ret = await updateUser(data);
    dispatch("loadTenants", true);
    return ret;
  },

  async removeTenantUser({ dispatch }, data) {
    const ret = await removeUser(data);
    dispatch("loadTenants", true);
    return ret;
  },

  async removeUserFromTenant({ dispatch }, data) {
    const ret = await removeUserTenant(data);
    dispatch("loadTenants", true);
    return ret;
  },

  async removeUsersFromTenant({ dispatch }, data) {
    data.emails.forEach(async (user) => {
      await removeUserTenant({
        userId: user.id,
        tenantsIds: [data.tenantId],
      });
    });
    dispatch("loadTenants", true);
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setTenants(state, tenants) {
    state.tenants = tenants;
  },
  setQuickFilter(state, filter) {
    state.quickFilters = filter;
  },
  setSearchQueryData(state, searchQueryData) {
    state.searchQueryData = searchQueryData;
  },
  setSearchQuery(state, searchQuery) {
    state.searchQuery = searchQuery;
  },
  setOwnerFilters(state, ownerFilters) {
    state.ownerFilters = ownerFilters;
  },
  showNotification(state, notification) {
    state.notification = notification;
  },
  clearNotification(state) {
    if (state.notification && state.notification.timeoutId) {
      clearInterval(state.notification.timeoutId);
    }
    state.notification = null;
  },
};

export default createStore({
  modules: {
    users,
    device,
    auth,
  },
  state,
  actions,
  mutations,
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
