<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 z-50 overflow-y-auto" @close="$emit('cancel')" :open="open">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </TransitionChild>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
            class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div
                class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                <span :class="[
                  getIconBackground(type),
                  'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                ]">
                  <component :is="getIcon(type)" class="w-5 h-5 text-white" aria-hidden="true" />
                </span>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                  {{ title }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" :class="[
                getButtonStyles(type),
                'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm'
              ]" @click="$emit('approve')">
                {{ action }}
              </button>
              <button v-if="type != 'confirm'" type="button"
                class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                @click="$emit('cancel')" ref="cancelButtonRef">
                {{ $t("common.action.cancel") }}
              </button>


            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ExclamationIcon, InformationCircleIcon } from "@heroicons/vue/outline";
import { CheckIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
  },
  props: ["open", "title", "description", "action", "type"],
  emits: ["approve", "cancel"],
  setup() {
    const getButtonStyles = (popupType) => {
      switch (popupType) {
        case "error":
          return "bg-red-600 hover:bg-red-700 focus:ring-red-500";
        case "info":
          return "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";
        case "ok":
          return "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";
        case "confirm":
          return "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";
      }
      return "bg-red-600 hover:bg-red-700 focus:ring-red-500";
    }
    const getIconBackground = (popupType) => {
      switch (popupType) {
        case "error":
          return "bg-red-500";
        case "info":
          return "bg-blue-500";
        case "ok":
          return "bg-green-500";
        case "confirm":
          return "bg-green-500";
      }
      return "bg-red-500";
    }
    const getIcon = (popupType) => {
      switch (popupType) {
        case "error":
          return ExclamationIcon;
        case "info":
          return InformationCircleIcon;
        case "ok":
          return CheckIcon;
        case "confirm":
          return CheckIcon;
      }
      return ExclamationIcon;
    }

    return {
      getButtonStyles,
      getIconBackground,
      getIcon
    }
  }
};
</script>
