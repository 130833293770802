<template>
  <div>
    <h1 class="pt-6 mb-2 font-medium text-left text-gray-900">
      {{ $t("device.header.dailyCheck") }}
    </h1>
    <dd class="mt-1 text-sm text-gray-900">
      <ul class="divide-y divide-gray-200 rounded-md">
        <li class="flex items-center justify-between py-3 pr-4 text-sm">
          <Toggle v-model="dailyCheckInIsActive" :disabled="!isEditAllowed" :caption="dailyCheckInIsActive ? $t('dailyCheck.form.active') : $t('dailyCheck.form.inactive')" />
        </li>
        <!-- check time-->
        <li v-show="dailyCheckInIsActive" class="flex items-center justify-between py-3 pr-4 text-sm">
          <div class="flex items-center flex-1 w-0">
            <CogIcon class="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
            <div class="flex flex-col flex-1 w-0 ml-4">
              <span class="truncate"> {{ $t("dailyCheck.form.checkTime") }}</span>
            </div>
          </div>
          <div class="relative flex-shrink-0 ml-4">
            <input v-model="dailyCheckInCheckTimeInput" type="text" v-maska data-maska="##:##" :disabled="!isEditAllowed" placeholder="hh:mm" :class="['w-32 text-gray-900 border border-gray-300 rounded-lg sm:text-sm',
        isCheckTimeValid ? 'focus:ring-blue-500 focus:border-blue-500' : 'border-red-500 focus:border-red-600 focus:ring-red-500']" />
          </div>
        </li>
        <li v-show="dailyCheckInIsActive" class="flex items-center justify-between py-3 pr-4 text-sm">
          <div class="flex items-center flex-1 w-0">
            <CogIcon class="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
            <div class="flex flex-col flex-1 w-0 ml-4">
              <span class="truncate"> {{ $t("dailyCheck.form.gracePeriod") }}</span>
              <span class="text-xs text-gray-400">
                {{
        $t("dailyCheck.form.remindersDescription", {
          firstReminder: Math.round(reminders[0]),
          secondReminder: Math.round(reminders[1]),
        })
      }}
              </span>
            </div>
          </div>
          <div class="relative flex-shrink-0 ml-4">
            <input v-model="dailyCheckInGracePeriod" min="30" max="1380" type="number" :disabled="!isEditAllowed" class="w-32 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
          </div>
        </li>
        <li v-show="dailyCheckInIsActive" class="flex items-center justify-between py-3 pr-4 text-sm">
          <Toggle v-model="dailyCheckInSuspended" :disabled="!isEditAllowed" :caption="$t('dailyCheck.form.suspended')" />
        </li>
        <li v-show="dailyCheckInSuspended && dailyCheckInIsActive" class="flex items-center justify-between py-3 pr-4 text-sm">
          <div class="flex items-center flex-1 w-0">
            <CogIcon class="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
            <div class="flex flex-col flex-1 w-0 ml-4">
              <span class="truncate"> {{ $t("dailyCheck.form.suspendedDate") }}</span>
            </div>
          </div>

          <div class="relative flex-shrink-0 ml-4">
            <input ref="dailyCheckInSuspendedDate" type="text" class="w-32 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500" placeholder="dd.mm.yyyy" @changeDate="updateConfigHandler" />
            <button @click="clearDate" class="absolute inset-y-0 right-0 flex items-center pr-2">
              <XIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </li>
      </ul>
    </dd>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { hasDeviceTenantPermission, USER_PERMISSIONS } from "@/lib/userHasPermission";
import Toggle from "@/components/ToggleButton";
import Datepicker from "flowbite-datepicker/Datepicker";
import { CogIcon } from "@heroicons/vue/outline/esm";
import { XIcon } from "@heroicons/vue/solid";
import debounce from "lodash.debounce";
import moment from "moment";

const parser = require("cron-parser");

export default {
  name: "DeviceDailyCheckIn",
  components: {
    Toggle,
    CogIcon,
    XIcon,
  },

  props: ["refresh"],
  emits: ["updateConfig"],

  setup(props, { emit }) {
    const store = useStore();
    const device = computed(() => store.state.device.current);

    const isEditAllowed = computed(() => hasDeviceTenantPermission(USER_PERMISSIONS.ADMIN));
    const dailyCheckInIsActive = ref(device.value.configV2.daily_check_in_app.enabled);
    const dailyCheckInSuspended = ref(device.value.configV2.daily_check_in_app.suspended);
    const dailyCheckInSuspendedDate = ref(null);
    const dailyCheckInGracePeriod = ref(
      moment.duration(device.value.configV2.daily_check_in_app.grace_period).as("minutes")
    );
    const dailyCheckInCheckTime = ref(device.value.configV2.daily_check_in_app.start);
    const dailyCheckInCheckTimeInput = ref(null);
    const reminders = ref(
      [moment.duration(device.value.configV2.daily_check_in_app.reminders[0]).as("minutes"),
      moment.duration(device.value.configV2.daily_check_in_app.reminders[1]).as("minutes")]
    );
    const isCheckTimeValid = ref(true);
    const confirmOpen = ref(false);
    const resetCaller = ref(false);

    onMounted(() => {
      dailyCheckInCheckTimeInput.value = cronToInputMask(device.value.configV2.daily_check_in_app.start);

      new Datepicker(dailyCheckInSuspendedDate.value, {
        minDate: new Date(),
        locale: "de_CH",
        format: "dd.mm.yyyy",
      });

      dailyCheckInSuspendedDate.value.datepicker.setDate(
        new Date(device.value.configV2.daily_check_in_app.suspension_end),
        {
          clear: true,
        }
      );
    });

    const clearDate = () => {
      dailyCheckInSuspendedDate.value.datepicker.setDate([], { clear: true });
    };

    watch(dailyCheckInIsActive, (dailyCheckInIsActive) => {
      if (!dailyCheckInIsActive) {
        dailyCheckInSuspended.value = false;
        dailyCheckInSuspendedDate.value = null;
      }
    });

    watch(dailyCheckInGracePeriod, (newValue) => {
      checkDailyCheckInGracePeriod(newValue);
    });

    const updateConfigHandler = () => {
      let endDate = null;

      if (dailyCheckInSuspendedDate.value && dailyCheckInSuspendedDate.value.datepicker && dailyCheckInSuspendedDate.value.datepicker.getDate() instanceof Date) {
        endDate = moment(dailyCheckInSuspendedDate.value.datepicker.getDate()).format("YYYY-MM-DD");
      }

      let graceChanged = false;

      if (moment.duration({ minutes: dailyCheckInGracePeriod.value }).toISOString() != moment.duration(device.value.configV2.daily_check_in_app.grace_period).toISOString()) {
        graceChanged = true;
      }

      emit("updateConfig", {
        enabled: dailyCheckInIsActive.value,
        suspended: dailyCheckInSuspended.value,
        suspensionEnd: endDate ?? null,
        gracePeriod: moment.duration({ minutes: dailyCheckInGracePeriod.value }).toISOString(),
        checkTime: inputToCron(dailyCheckInCheckTimeInput.value, dailyCheckInCheckTime.value),
        reminders: [
          graceChanged ? moment.duration({ minutes: dailyCheckInGracePeriod.value * 0.5 }).toISOString() : moment.duration(reminders.value[0], 'minutes').toISOString(),
          graceChanged ? moment.duration({ minutes: dailyCheckInGracePeriod.value * 0.75 }).toISOString() : moment.duration(reminders.value[1], 'minutes').toISOString(),
        ],
        valid: isCheckTimeValid.value
      });
    };

    watch(dailyCheckInCheckTimeInput, () => {
      if (!moment(dailyCheckInCheckTimeInput.value, "hh:mm").isValid()) {
        isCheckTimeValid.value = false;
      } else {
        isCheckTimeValid.value = true;
      }
    })

    watch(
      [
        dailyCheckInIsActive,
        dailyCheckInSuspended,
        dailyCheckInSuspendedDate,
        dailyCheckInGracePeriod,
        dailyCheckInCheckTimeInput,
      ],
      () => {
        updateConfigHandler();
      }
    );

    watch(device, () => {
      if (device.value) {
        reset();
      }
    });

    watch(() => props.refresh, () => {
      if (props.refresh) {
        reset();
      }
    })

    const reset = () => {
      dailyCheckInIsActive.value = device.value.configV2.daily_check_in_app.enabled;
      dailyCheckInSuspended.value = device.value.configV2.daily_check_in_app.suspended;

      dailyCheckInGracePeriod.value = moment
        .duration(device.value.configV2.daily_check_in_app.grace_period)
        .as("minutes");
      dailyCheckInCheckTime.value = device.value.configV2.daily_check_in_app.start;
      dailyCheckInCheckTimeInput.value = cronToInputMask(device.value.configV2.daily_check_in_app.start);
      dailyCheckInSuspendedDate.value.datepicker.setDate(
        new Date(device.value.configV2.daily_check_in_app.suspension_end),
        {
          clear: true,
        }
      );

      reminders.value = [
        moment.duration(device.value.configV2.daily_check_in_app.reminders[0]).as("minutes"),
        moment.duration(device.value.configV2.daily_check_in_app.reminders[1]).as("minutes")
      ]

      resetCaller.value = true;
    }

    const checkDailyCheckInGracePeriod = debounce((newValue) => {

      dailyCheckInGracePeriod.value = Math.min(Math.max(30, newValue), 1380);
      if (resetCaller.value == false) {
        reminders.value = [
          Math.round(newValue * 0.5),
          Math.round(newValue * 0.75),
        ]
      }
      resetCaller.value = false;

    }, 500);

    const cronToInputMask = (cronStr) => {
      try {
        const interval = parser.parseExpression(cronStr);
        const fields = JSON.parse(JSON.stringify(interval.fields));
        return `${fields.hour < 10 ? "0" + fields.hour.toString() : fields.hour}:${fields.minute < 10 ? "0" + fields.minute.toString() : fields.minute
          }`;
      } catch (error) {
        console.error(error)
        return "";
      }
    };

    const inputToCron = (inputStr, originalCron) => {
      try {
        const interval = parser.parseExpression(originalCron);
        const fields = JSON.parse(JSON.stringify(interval.fields));
        fields.hour = [+inputStr.split(":")[0]];
        fields.minute = [+inputStr.split(":")[1]];
        const modifiedInterval = parser.fieldsToExpression(fields);
        return modifiedInterval.stringify();
      } catch (error) {
        console.error(error)
        return "";
      }
    };

    return {
      device,
      isEditAllowed,
      dailyCheckInIsActive,
      dailyCheckInSuspended,
      dailyCheckInSuspendedDate,
      dailyCheckInCheckTime,
      dailyCheckInCheckTimeInput,
      dailyCheckInGracePeriod,
      clearDate,
      updateConfigHandler,
      confirmOpen,
      isCheckTimeValid,
      reminders
    };
  },
};
</script>
