<template>

  <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="25" height="27">
    <path
      d="M17.143 2.146c0-1.908-2.309-2.864-3.657-1.514L7.057 7.061h-2.76c-1.63 0-3.311.948-3.8 2.721A13.943 13.943 0 0 0 0 13.49c0 1.283.173 2.526.5 3.707.487 1.772 2.169 2.722 3.799 2.722h2.757l6.428 6.428c1.35 1.35 3.659.395 3.659-1.514V2.146ZM20.617 7.428a1.071 1.071 0 0 1 1.516 0 8.574 8.574 0 0 1 0 12.123 1.071 1.071 0 0 1-1.514-1.516 6.427 6.427 0 0 0 0-9.091 1.071 1.071 0 0 1 0-1.515l-.002-.001Z" />
  </svg>

</template>

<script>
export default {
  name: 'VolumeQuietIcon',
}
</script>
