import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";

export default function render() {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        viewBox: "0 0 24 24",
        stroke: "none",
      },
      [
        _createVNode("path", {
          fill: "none",
          d: "M0 0h24v24H0V0z",
        }),
        _createVNode("path", {
          d: "M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4zM11 20v-5.5H9L13 7v5.5h2L11 20z",
        }),
      ]
    )
  );
}
