<template>


  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" class="fill-current">
    <path
      d="M17.143 2.146c0-1.908-2.309-2.864-3.657-1.514L7.057 7.061h-2.76c-1.63 0-3.311.948-3.8 2.721A13.943 13.943 0 0 0 0 13.49c0 1.283.173 2.526.5 3.707.487 1.772 2.169 2.722 3.799 2.722h2.757l6.428 6.428c1.35 1.35 3.659.395 3.659-1.514V2.146Zm7.263 1.495a1.071 1.071 0 0 1 1.514 0c5.44 5.438 5.44 14.257 0 19.697a1.07 1.07 0 1 1-1.514-1.515 11.785 11.785 0 0 0 0-16.668 1.072 1.072 0 0 1 0-1.514Z" />
    <path
      d="M20.617 7.428a1.071 1.071 0 0 1 1.516 0 8.574 8.574 0 0 1 0 12.123 1.071 1.071 0 0 1-1.514-1.516 6.427 6.427 0 0 0 0-9.091 1.071 1.071 0 0 1 0-1.515l-.002-.001Z" />
  </svg>

</template>

<script>
export default {
  name: 'VolumeDefaultIcon',
}
</script>
