<template>
    <div>
        <span class="relative flex">
            <h1 class="pt-6 font-medium text-left text-gray-900">
                {{ $t("device.header.hydrationReminder") }}
            </h1>
            <div class="relative px-2 pt-6 text-sm font-semibold text-left text-gray-900 group" v-if="allowEdit()">
                <ExclamationCircleIcon class="w-5 text-red-500 group hover:text-cyan-800" aria-hidden="true" />
                <div class="absolute z-20 invisible max-w-sm px-3 py-3 mt-2 origin-top bg-white divide-y divide-gray-100 rounded-md shadow-lg w-80 group-hover:visible ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {{ $t("hydrationReminder.updateVersion") }}
                </div>
            </div>
        </span>

        <dd class="mt-1 text-sm text-gray-900">
            <ul class="rounded-md">
                <li class="flex items-center justify-between py-3 pr-4 text-sm">
                    <Toggle v-model="hydrationReminderActive" :disabled="allowEdit()" :caption="hydrationReminderActive ? $t('dailyCheck.form.active') : $t('dailyCheck.form.inactive')" :subtitle="hydrationReminderActive
                        ? $t('hydrationReminder.titleDescription', { animationSlot: getTimestamps(shownTimeStamps) })
                        : ''
                        " />
                </li>
                <li v-show="hydrationReminderActive" class="flex items-center justify-between py-3 pr-4 text-sm">
                    <div class="flex items-center flex-1 w-0">
                        <CogIcon class="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />

                        <div class="flex flex-col flex-1 w-0 ml-4">
                            <span class="flex-1">{{ $t("hydrationReminder.previewSound") }}</span>
                            <span class="text-xs text-gray-400">
                                {{ $t("hydrationReminder.previewSoundDescription") }}
                            </span>
                        </div>
                    </div>
                    <div class="relative ml-4">
                        <button @click="playSound()" class="flex items-center justify-center w-24 px-1 py-2 text-sm text-gray-900 bg-white rounded-md cursor-pointer focus:outline-none hover:ring-indigo-600 active:ring-offset-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:flex-1">
                            {{ $t("hydrationReminder.play") }}
                        </button>
                    </div>
                </li>

                <li v-show="hydrationReminderActive" class="flex items-center justify-between py-3 pr-4 text-sm">
                    <div class="flex items-center flex-1 w-0">
                        <CogIcon class="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                        <div class="flex flex-col flex-1 w-0 ml-4">
                            <span>{{ $t("hydrationReminder.reminderInterval") }}</span>
                        </div>
                    </div>
                    <div class="relative flex-1 ml-4">
                        <RadioGroup v-model="selectedInterval" class="mt-2">
                            <div class="grid grid-cols-3 gap-3">
                                <RadioGroupOption as="template" v-for="option in intervals" :key="option.name" :value="option" v-slot="{ active, checked }">
                                    <div :class="[
                                        'cursor-pointer focus:outline-none',
                                        active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                                        checked
                                            ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                                            : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                        'flex items-center justify-center rounded-md py-2 px-1 text-sm  sm:flex-1',
                                    ]">
                                        <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
                                    </div>
                                </RadioGroupOption>
                            </div>
                        </RadioGroup>
                    </div>
                </li>
                <li v-show="hydrationReminderActive" class="items-center justify-between py-3 pr-4 mb-8 text-sm">
                    <div class="flex items-center flex-1 mb-4">
                        <CogIcon class="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                        <div class="flex flex-col flex-1 w-0 ml-4">
                            <span class="flex-1">{{ $t("hydrationReminder.intervalSelection") }}</span>
                            <span class="text-xs text-gray-400">
                                {{ $t("hydrationReminder.intervalDescription") }}
                            </span>
                        </div>
                    </div>
                    <DateRangeSlider v-if="selectedTimeIntervals != null" :selection="selectedTimeIntervals" @emitChange="selectionChangeHandler"></DateRangeSlider>
                </li>
            </ul>
        </dd>
    </div>
</template>

<script>
import { nextTick, computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { hasDeviceTenantPermission, USER_PERMISSIONS } from "@/lib/userHasPermission";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { ExclamationCircleIcon } from "@heroicons/vue/outline";

import { CogIcon } from "@heroicons/vue/outline/esm";

import Toggle from "@/components/ToggleButton";
import DateRangeSlider from "@/components/sliders/DateRangeSlider";
import moment from "moment";

export default {
    name: "HydrationReminderSettings",
    components: {
        Toggle,
        DateRangeSlider,
        RadioGroup,
        RadioGroupLabel,
        RadioGroupOption,
        ExclamationCircleIcon,
        CogIcon,
    },

    props: ["refresh"],
    emits: ["updateConfig"],

    setup(props, { emit }) {
        const intervals = [
            { name: "1h", value: 1 },
            { name: "2h", value: 2 },
            { name: "3h", value: 3 },
        ];
        const offset = -5;

        const store = useStore();
        const device = computed(() => store.state.device.current);
        const isEditAllowed = computed(() => hasDeviceTenantPermission(USER_PERMISSIONS.ADMIN));

        const hydrationReminderActive = ref(device.value.configV2.hydration_reminder_app.enabled);
        const selectedInterval = ref(
            intervals[moment.duration(device.value.configV2.hydration_reminder_app.interval).hours() - 1]
        );
        const selectedTimeIntervals = ref(null);
        const shownTimeStamps = ref(null);

        const getTimestamps = (values) => {
            if (values) return values.join(", ");
        };

        const getDateOfRange = (val) => {
            let parts = val.split(":");
            let d = new Date();
            d.setHours(parseInt(parts[0], 10));
            d.setMinutes(parseInt(parts[1], 10));
            d.setSeconds(0);
            return d;
        };

        const getOffsetDateString = (value) => {
            let d = getDateOfRange(value);
            d.setMinutes(d.getMinutes() + offset);
            return [d.getHours(), d.getMinutes()];
        };

        const getIntervalValues = (startTime, endTime) => {
            let tmpTimeStamps = [];
            while (startTime <= endTime) {
                tmpTimeStamps.push(startTime.getHours() + ":" + (startTime.getMinutes() == 0 ? "00" : startTime.getMinutes()));
                startTime.setHours(startTime.getHours() + selectedInterval.value.value);
            }
            return tmpTimeStamps;
        };

        const calcTimeStamps = (selection) => {
            let timeStamps = [];
            if (selection[1] == selection[2]) {
                let startTime = getDateOfRange(selection[0]);
                let endTime = getDateOfRange(selection[3]);
                let tmpTimeStamps = getIntervalValues(startTime, endTime);
                timeStamps.push.apply(timeStamps, tmpTimeStamps);
            } else {
                let startTime = getDateOfRange(selection[0]);
                let endTime = getDateOfRange(selection[1]);
                let tmpTimeStamps = getIntervalValues(startTime, endTime);
                timeStamps.push.apply(timeStamps, tmpTimeStamps);

                startTime = getDateOfRange(selection[2]);
                endTime = getDateOfRange(selection[3]);
                tmpTimeStamps = getIntervalValues(startTime, endTime);
                timeStamps.push.apply(timeStamps, tmpTimeStamps);
            }

            return timeStamps;
        };

        onMounted(() => {
            if (
                device.value.configV2.hydration_reminder_app.periods &&
                device.value.configV2.hydration_reminder_app.periods[0] &&
                device.value.configV2.hydration_reminder_app.periods[1]
            ) {
                selectedTimeIntervals.value = device.value.configV2.hydration_reminder_app.periods[0]
                    .concat(device.value.configV2.hydration_reminder_app.periods[1])
                    .map((time) => time.slice(0, -3));
            } else {
                selectedTimeIntervals.value = ["09:00", "12:00", "14:00", "17:00"];
            }

            shownTimeStamps.value = calcTimeStamps(selectedTimeIntervals.value);
        });

        watch(selectedInterval, (newInterval) => {
            selectedInterval.value = newInterval;
            selectionChangeHandler(selectedTimeIntervals.value);
        });

        const selectionChangeHandler = (selection) => {
            selectedTimeIntervals.value = selection;
            shownTimeStamps.value = [];

            if (selection[1] == selection[2]) {
                let startTime = getDateOfRange(selection[0]);
                let endTime = getDateOfRange(selection[3]);
                let tmpTimeStamps = getIntervalValues(startTime, endTime);
                shownTimeStamps.value.push.apply(shownTimeStamps.value, tmpTimeStamps);
            } else {
                let startTime = getDateOfRange(selection[0]);
                let endTime = getDateOfRange(selection[1]);
                let tmpTimeStamps = getIntervalValues(startTime, endTime);
                shownTimeStamps.value.push.apply(shownTimeStamps.value, tmpTimeStamps);

                startTime = getDateOfRange(selection[2]);
                endTime = getDateOfRange(selection[3]);
                tmpTimeStamps = getIntervalValues(startTime, endTime);
                shownTimeStamps.value.push.apply(shownTimeStamps.value, tmpTimeStamps);
            }
        };

        const getCronTimings = () => {
            let timings = [];
            for (let times of shownTimeStamps.value) {
                let offsets = getOffsetDateString(times);
                timings.push(offsets[1] + " " + offsets[0] + " * * *");
            }
            return timings;
        };

        watch([hydrationReminderActive, selectedInterval, selectedTimeIntervals], () => {
            if (selectedTimeIntervals.value) {
                emit("updateConfig", {
                    enabled: hydrationReminderActive.value,
                    interval: moment.duration({ hours: selectedInterval.value.value }).toISOString(),
                    timings: getCronTimings(),
                    periods: [
                        [selectedTimeIntervals.value[0] + ":00", selectedTimeIntervals.value[1] + ":00"],
                        [selectedTimeIntervals.value[2] + ":00", selectedTimeIntervals.value[3] + ":00"],
                    ],
                    audio: device.value.configV2.hydration_reminder_app.audio,
                });
            }
        });

        const playSound = () => {
            // for now hardcoded file, since there is no file selection yet.
            var audio = new Audio(require("@/assets/hydration_reminder_default.mp3"));
            audio.play();
        };

        const allowEdit = () => {
            if (!isEditAllowed.value) return true;

            if (device.value && device.value.firmware && device.value.firmware.version) {
                let splitFirmware = device.value.firmware.version.split(".");

                if (parseInt(splitFirmware[0]) < 2023) return true;
                if (parseInt(splitFirmware[0]) > 2023) return false;
                if (parseInt(splitFirmware[1]) >= 3 && parseInt(splitFirmware[2]) >= 3) return false;
            }
            return true;
        };

        watch(device, async () => {
            if (device.value) {
                await reset()
            }
        });

        watch(() => props.refresh, async () => {
            if (props.refresh) {
                await reset()
            }
        })

        const reset = async () => {
            hydrationReminderActive.value = device.value.configV2.hydration_reminder_app.enabled;
            selectedInterval.value =
                intervals[moment.duration(device.value.configV2.hydration_reminder_app.interval).hours() - 1];

            selectedTimeIntervals.value = null;
            await nextTick();
            selectedTimeIntervals.value = device.value.configV2.hydration_reminder_app.periods[0]
                .concat(device.value.configV2.hydration_reminder_app.periods[1])
                .map((time) => time.slice(0, -3));

            shownTimeStamps.value = calcTimeStamps(selectedTimeIntervals.value);
        }

        return {
            device,
            isEditAllowed,
            hydrationReminderActive,
            intervals,
            selectedInterval,
            selectedTimeIntervals,
            shownTimeStamps,
            getTimestamps,
            playSound,
            selectionChangeHandler,
            allowEdit,
        };
    },
};
</script>
