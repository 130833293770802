import Store from "@/store";
import { Auth } from "aws-amplify";

export const AuthFilter = async (to, from, next) => {
  await Auth.currentAuthenticatedUser({ bypassCache: true })
    .then((user) => {
      Store.commit("auth/setUser", user);
      if (from.name == "login" && to.name != "devices" && user && user.username) {
        next({ name: "devices" });
      } else {
        next();
      }
    })
    .catch(async () => {
      Store.commit("auth/setUser", null);
      if (to.name !== "login") {
        next({ name: "login" });
      } else {
        next();
      }
    });
};
