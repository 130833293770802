<template>
    <div>
        <h1 class="font-medium text-left text-gray-900">
            {{ $t("device.header.co2Toggle") }}
        </h1>

        <dd class="mt-1 text-sm text-gray-900">
            <ul class="divide-y divide-gray-200 rounded-md">
                <li class="flex items-center justify-between py-3 pr-4 text-sm">
                    <Toggle v-model="co2Active" :disabled="!isEditAllowed" :caption="co2Active ? $t('dailyCheck.form.active') : $t('dailyCheck.form.inactive')" />
                </li>
            </ul>
        </dd>
    </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { hasDeviceTenantPermission, USER_PERMISSIONS } from "@/lib/userHasPermission";
import Toggle from "@/components/ToggleButton";

export default {
    name: "DeviceCo2Setting",
    components: {
        Toggle,
    },

    props: ["refresh"],
    emits: ["updateConfig"],

    setup(props, { emit }) {
        const store = useStore();
        const device = computed(() => store.state.device.current);
        const confirmOpen = ref(false);
        const co2Active = ref(device.value.configV2.co2_app.enabled);

        const isEditAllowed = computed(() => hasDeviceTenantPermission(USER_PERMISSIONS.ADMIN));

        watch(co2Active, () => {
            emit("updateConfig", co2Active);
        });

        watch(device, () => {
            if (device.value)
                reset()
        })

        watch(() => props.refresh, () => {
            if (props.refresh) {
                reset()
            }
        })

        const reset = () => {
            co2Active.value = device.value.configV2.co2_app.enabled
        }

        return {
            device,
            isEditAllowed,
            co2Active,
            confirmOpen,
        };
    },
};
</script>
