// https://boxicons.com/
import {createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock} from "vue";

export default function render() {
    return (
        _openBlock(),
            _createBlock(
                "svg",
                {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: 24,
                    height: 24,
                    fill: "currentColor",
                    style: "transform: ;msFilter:;"
                },
                [
                    _createVNode("path", {
                        d: "M3 16h2v5H3zm4-3h2v8H7zM21 3h-2v14.59l-2-2V7h-2v6.59l-2-2V10h-1.59l-7.7-7.71-1.42 1.42 18 18 1.42-1.42-.71-.7V3zm-6 18h1.88L15 19.12V21zm-4 0h2v-3.88l-2-2V21z",
                    }),
                ]
            )
    );
}
