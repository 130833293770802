<template>


  <div v-if="loading" class="flex justify-center w-full h-full mt-48">
    <LoadingSpinner></LoadingSpinner>
  </div>

  <div v-else class="h-full max-h-full pb-24 m-5 mr-10 overflow-scroll">
    <h1 v-if="isCreate" class="mb-5 text-lg font-medium leading-6 text-gray-900">
      {{ $t("userManagement.tab.createTenant") }}
    </h1>
    <h1 v-else class="mb-5 text-lg font-medium leading-6 text-gray-900">
      {{ $t("userManagement.tab.updateTenant") }}
    </h1>

    <div class="w-full mx-auto">
      <div v-if="!isCreate" class="flex flex-col w-full mx-auto mb-5 xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 py-2 whitespace-nowrap">
          {{ $t("userManagement.tenantTable.slug") }}
        </div>
        <div>
          <input type="text" v-model="tenantSlug" disabled="true" class="w-full text-gray-500 border border-gray-300 rounded-lg sm:text-sm" :placeholder="$t('userManagement.tenantTable.slug')" />
        </div>
      </div>
      <div class="flex flex-col w-full mx-auto mb-5 xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 py-2 whitespace-nowrap">{{ $t("userManagement.tenantTable.name") }}{{ " " }} *</div>
        <div>

          <input type="text" :disabled="!isCreate" v-model="tenantName" :class='["w-full text-gray-900 border border-gray-300 rounded-lg disabled:opacity-80 sm:text-sm", tenantName && tenantName.length ? "focus:ring-indigo-500 focus:border-indigo-500" : "border-red-500 focus:border-red-600 focus:ring-red-500"]' :placeholder="$t('userManagement.tenantTable.name')" />
        </div>
      </div>

      <div class="flex flex-col w-full mx-auto mb-5 xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 pt-2 whitespace-nowrap">
          {{ $t("userManagement.tenantTable.devices") }}
        </div>


        <div class="flex flex-col w-full mx-auto mt-2">
          <div v-if="!isCreate">
            <span class="px-2 font-thin"> {{ $t("userManagement.tenantTable.deviceTenants") }} ({{ devicesOfTenant.length }})</span>
            <div v-if="!showAllDevicesOfTenants" class="flex flex-wrap max-w-full gap-2 py-2 pl-3">
              <div v-for="(device, index) in devicesOfTenant.slice(0, 10)" :key="index" class="inline-flex items-center h-8 p-2 mx-2 text-xs font-normal text-gray-800 bg-gray-200 rounded-full ring-1 ring-inset ring-gray-500/10">
                {{ device.serialNumber }}
              </div>
              <div v-if="devicesOfTenant.length > 10" :key="index" class="inline-flex items-center h-8 p-2 mx-2 text-xs font-normal text-gray-800 bg-gray-200 rounded-full ring-1 ring-inset ring-gray-500/10">... </div>
              <div v-if="devicesOfTenant.length > 10" @click="showAllDevicesOfTenants = true" class="w-full px-2 py-2 text-xs font-normal text-gray-800 cursor-pointer hover:underline">{{ $t("userManagement.tenantTable.showAll") }}</div>
            </div>
            <div v-else class="flex flex-wrap max-w-full gap-2 py-2 pl-3 overflow-scroll max-h-96">
              <div v-for="(device, index) in devicesOfTenant" :key="index" class="inline-flex items-center h-8 p-2 mx-2 text-xs font-normal text-gray-800 bg-gray-200 rounded-full ring-1 ring-inset ring-gray-500/10">
                {{ device.serialNumber }}
              </div>
              <div v-if="devicesOfTenant.length > 10" @click="showAllDevicesOfTenants = false" class="w-full px-2 py-2 text-xs font-normal text-gray-800 cursor-pointer hover:underline">{{ $t("userManagement.tenantTable.showLess") }}</div>
            </div>
          </div>

          <div>
            <span class="px-2 pt-4 font-thin" v-if="!isCreate && selectedDevices.length > 0"> {{ $t("userManagement.tenantTable.newDevices") }}</span>

            <div v-if="!showAllSelectedDevice" class="flex flex-wrap max-w-full gap-2 py-2 pl-3">
              <div v-for="(device, index) in selectedDevices.slice(0, 10)" :key="index" class="inline-flex items-center h-8 p-2 mx-2 text-xs font-normal text-gray-800 bg-gray-200 rounded-full ring-1 ring-inset ring-gray-500/10">
                {{ device.serialNumber }}
                <button type="button" @click="removeDevice(device)" class="group relative -mr-1 h-3.5 w-3.5 rounded-md hover:bg-gray-500/20">
                  <span class="sr-only">Remove</span>
                  <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                    <path d="M4 4l6 6m0-6l-6 6" />
                  </svg>
                  <span class="absolute -inset-1" />
                </button>
              </div>
              <div v-if="selectedDevices.length > 10" :key="index" class="inline-flex items-center h-8 p-2 mx-2 text-xs font-normal text-gray-800 bg-gray-200 rounded-full ring-1 ring-inset ring-gray-500/10">... </div>
              <div v-if="selectedDevices.length > 10" @click="showAllSelectedDevice = true" class="w-full px-2 py-2 text-xs font-normal text-gray-800 cursor-pointer hover:underline">{{ $t("userManagement.tenantTable.showAll") }}</div>
            </div>

            <div v-else class="flex flex-wrap max-w-full gap-2 py-2 pl-3 overflow-scroll max-h-96">
              <span class="px-2 font-thin" v-if="!isCreate"> {{ $t("userManagement.tenantTable.newDevices") }}</span>

              <div v-for="(device, index) in selectedDevices" :key="index" class="inline-flex items-center h-8 p-2 mx-2 text-xs font-normal text-gray-800 bg-gray-200 rounded-full ring-1 ring-inset ring-gray-500/10">
                {{ device.serialNumber }}
                <button type="button" @click="removeDevice(device)" class="group relative -mr-1 h-3.5 w-3.5 rounded-md hover:bg-gray-500/20">
                  <span class="sr-only">Remove</span>
                  <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                    <path d="M4 4l6 6m0-6l-6 6" />
                  </svg>
                  <span class="absolute -inset-1" />
                </button>
              </div>
              <div v-if="selectedDevices.length > 10" @click="showAllSelectedDevice = false" class="w-full px-2 py-2 text-xs font-normal text-gray-800 cursor-pointer hover:underline">{{ $t("userManagement.tenantTable.showLess") }}</div>
            </div>
          </div>

          <label for="ownerName" class="block ml-2 text-sm font-medium text-gray-700">
            {{ $t("userManagement.tenantTable.serialNumber") }}</label>

          <Combobox v-model="selectedDevices" multiple as="div">
            <div class="relative">
              <div class="relative flex w-full overflow-hidden text-left bg-white cursor-default py-auto">
                <ComboboxInput class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" :displayValue="(device) => device.serialNumber" @change="deviceQuery = $event.target.value" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                  <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>
              </div>

              <transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" @after-leave="deviceQuery = ''">
                <ComboboxOptions class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none">
                  <div v-if="filteredDevices.length === 0 && query !== ''" class="relative px-4 py-2 text-gray-700 cursor-default select-none">
                    {{ $t("error.notFound") }}
                  </div>

                  <ComboboxOption v-for="device in filteredDevices" as="template" :key="device.id" :value="device" v-slot="{ selected, active }">
                    <li class="relative py-2 pl-10 pr-4 cursor-default select-none" :class="{ 'bg-teal-600 text-white': active, 'text-gray-900': !active }">
                      <span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                        {{ device.serialNumber }}
                      </span>
                      <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3" :class="{ 'text-white': active, 'text-teal-600': !active }">
                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </transition>
            </div>
          </Combobox>
        </div>
      </div>

      <div class="flex flex-col w-full mx-auto mt-5 mb-6 xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 pt-2 whitespace-nowrap">
          {{ $t("userManagement.tenantTable.users") }}
        </div>

        <div v-for="(email, index) in emails" :key="index" class="flex flex-row gap-2">
          <div class="flex flex-col w-1/2 mx-auto mt-2">
            <label for="ownerName" class="block ml-2 text-sm font-medium text-gray-700">
              {{ $t("userManagement.userTable.email") }}</label>
            <div>
              <input type="text" v-model="emails[index].mail" class="w-full mt-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-indigo-500 focus:border-indigo-500" :placeholder="$t('userManagement.userTable.email')" />
            </div>
          </div>

          <div class="flex flex-col w-1/2 mx-auto mt-2">
            <label for="ownerName" class="block ml-2 text-sm font-medium text-gray-700">
              {{ $t("userManagement.userTable.role") }}</label>
            <Combobox as="div" v-model="emails[index].role">
              <div class="relative mt-2">
                <ComboboxInput class="z-0 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                  <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="allRoles.length > 0" class="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ComboboxOption v-for="selectedRole in allRoles" :key="selectedRole" :value="selectedRole" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-1 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ selectedRole }}
                      </span>

                      <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>

          <div class="flex flex-col mx-auto">
            <div class="w-full h-full"></div>
            <div class="flex w-16 h-full">
              <button v-if="emails.length != 1" class="flex items-center w-8 ml-1 text-gray-400" @click="removeEmail(index)">
                <MinusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
              </button>
              <button v-if="index + 1 == emails.length" class="flex items-center w-8 ml-1 text-gray-400" @click="addEmail">
                <PlusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <div class="flex justify-end flex-shrink-0 py-4 mt-5">
          <button type="button" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="$emit('cancel')">
            {{ $t("common.action.cancel") }}
          </button>
          <button type="submit" :disabled="!isValid() || !didUserChange()" class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50" @click="confirmUpdate = true">
            {{ $t("common.action.save") }}
          </button>
        </div>
      </div>
    </div>

    <SimpleAlert @cancel="confirmUpdate = false" @approve="save" :open="confirmUpdate" :title="isCreate ? $t('userManagement.action.createTenant') : $t('userManagement.action.updateTenant')" :description="isCreate ? $t('userManagement.action.createTenantDescription') : $t('userManagement.action.updateTenantDescription')" :action="$t('common.action.apply')" type="info"></SimpleAlert>
  </div>

</template>

<script>
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/vue/outline";
import { computed } from "vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SimpleAlert from "@/components/modals/SimpleAlert";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "CreateUpdateTenant",

  components: {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ChevronDownIcon,
    ComboboxOption,
    ComboboxOptions,
    CheckIcon,
    MinusCircleIcon,
    PlusCircleIcon,
    SimpleAlert,
    LoadingSpinner
  },

  props: ["tenant", "isCreate"],
  emits: ["cancel"],


  async mounted() {
    await Promise.all([
      this.$store.dispatch("device/getDevices", true),
      this.$store.dispatch("users/getUsers")
    ]);
  },

  setup(props, { emit }) {

    const store = useStore();
    const { t } = useI18n();

    const loading = computed(() => store.state.device.loading || store.state.users.loading)

    const tenantId = ref(props.tenant.id);
    const tenantSlug = ref(props.tenant.slug);
    const tenantName = ref(props.tenant.name);

    const users = computed(() => store.state.users.all);

    const confirmUpdate = ref(false);

    const getTenantUsers = () => {
      let tUsers = [];
      for (let user of users.value) {
        let value = user[1];
        if (value.tenants) {
          let foundTenant = value.tenants.filter((tenant) => tenant.tenant.id == tenantId.value)
          if (foundTenant.length > 0) {
            tUsers.push({ id: value.id, mail: value.email, role: value.tenants[0].role })
          }
        }
      }
      if (tUsers.length > 0)
        return tUsers;

      return [{}];
    }

    const emails = ref(getTenantUsers());
    const allRoles = ["ADMIN", "VIEWER"];

    const allDevices = computed(() => Array.from(store.state.device.all.values()));

    const tenantDevices = computed(() => (tenantSlug.value && allDevices.value) ?
      allDevices.value.filter((device) => {
        if (device.id.includes(tenantSlug.value)) return device
      })
      :
      [])


    const deviceQuery = ref("");

    const getFilteredDevices = () => {

      let deviceList = allDevices.value;
      if (!props.isCreate) {
        let storedDevices = tenantDevices.value ? tenantDevices.value : []
        deviceList = allDevices.value.filter((device) => !storedDevices.some((storedDevice) => device.id == storedDevice.id))
      }

      return deviceQuery.value === ""
        ? deviceList
        : deviceList.filter((device) => {
          return device.serialNumber.includes(deviceQuery.value);
        })
    }


    const devicesOfTenant = computed(() => tenantDevices.value ? tenantDevices.value : [])
    const selectedDevices = ref([]);
    const filteredDevices = computed(() =>
      getFilteredDevices()
    );

    const showAllSelectedDevice = ref(false);
    const showAllDevicesOfTenants = ref(false);

    const isValid = () => {
      let valid = true;

      if (!tenantName.value) return false;
      if (tenantName.value == "" || tenantName.value.length == 0) return false;

      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


      for (let i = 0; i < emails.value.length; i++) {
        let email = emails.value[i].mail && emails.value[i].mail.length;
        let role = emails.value[i].role && emails.value[i].role.length;
        let isEmailValid = true;

        if (email) {
          isEmailValid = emailReg.test(emails.value[i].mail);
        }
        if (!role && !email)
          continue;
        if (!isEmailValid)
          return false;
        if (!role || !email)
          return false;

      }
      return valid;
    }

    const save = async () => {

      if (!tenantName.value) {
        return;
      }

      let creatTenantResp;
      if (props.isCreate) {
        creatTenantResp = await store.dispatch("createTenant", tenantName.value);
      }

      if (creatTenantResp && creatTenantResp.tenant.create.ok && creatTenantResp.tenant.create.node.id) {
        showNotification(
          "info",
          t("notifications.mutations.success"),
          t("notifications.mutations.tenantCreated", { tenant: tenantName.value })
        );

        tenantId.value = creatTenantResp.tenant.create.node.id
      }

      if (!tenantId.value) {
        showNotification(
          "error",
          t("notifications.mutations.error"),
          t("notifications.mutations.templateStoredFailure")
        );
        return;
      }

      // TODO improvement only pass added devices on update tenant. now all are passed
      if (selectedDevices.value && selectedDevices.value.length > 0) {
        const deviceIds = selectedDevices.value.map((device) => { return device.id })
        await store.dispatch("device/batchUpdateOwner", {
          deviceIds: deviceIds,
          tenant: { id: tenantId.value }
        });
      }

      if (emails.value && emails.value.length > 0) {
        await handleUsers();
      }

      emit("refreshData")
      emit("cancel")

    };

    const handleUsers = async () => {

      const oldUsers = getTenantUsers();
      const removedUsers = oldUsers.filter((oldUser) => !emails.value.some((user) => oldUser.id == user.id))
      const newUsers = emails.value.filter((user) => !oldUsers.some((oldUser) => user.id == oldUser.id));

      let updatedUsers = emails.value.filter((oldUser) => !oldUsers.some((user) => oldUser.mail == user.mail && oldUser.role == user.role))
      updatedUsers = updatedUsers.filter((updatedUser) => !newUsers.some((newUser) => updatedUser.id == newUser.id));

      if (!props.isCreate)
        await removeEmailsHandler(removedUsers.concat(updatedUsers));

      await inviteNewEmailsHandler(newUsers.concat(updatedUsers));
    }

    const inviteNewEmailsHandler = async (inviteEmails) => {
      if (inviteEmails.length > 0) {
        await store.dispatch("inviteUsersForTenant", {
          tenantId: tenantId.value,
          emails: inviteEmails,
        });
      }
    }

    const removeEmailsHandler = async (removeEmails) => {
      if (removeEmails.length > 0) {
        await store.dispatch("removeUsersFromTenant", {
          tenantId: tenantId.value,
          emails: removeEmails,
        });
      }
    }

    const showNotification = async (type, title, text) => {
      await store.dispatch("showNotification", {
        title: title,
        text: text,
        type: type,
      });
    };

    const removeDevice = (device) => {
      selectedDevices.value = selectedDevices.value.filter((d) => d.serialNumber != device.serialNumber);
    };

    const removeEmail = (index) => {
      emails.value.splice(index, 1);
    };

    const addEmail = () => {
      emails.value.push({});
    };

    const didUserChange = () => {
      if (props.isCreate)
        return true;

      let didChange = false;

      if (selectedDevices.value.length > 0) {
        didChange = true;
      }

      const localUsers = getTenantUsers();
      if (JSON.stringify(emails.value) != JSON.stringify(localUsers)) {
        didChange = true;
      }


      return didChange;
    }


    return {
      tenantSlug,
      tenantName,
      emails,
      allRoles,
      allDevices,
      selectedDevices,
      deviceQuery,
      filteredDevices,
      removeDevice,
      removeEmail,
      addEmail,
      save,
      isValid,
      showAllSelectedDevice,
      showAllDevicesOfTenants,
      users,
      confirmUpdate,
      loading,
      devicesOfTenant,
      didUserChange
    };
  },
};
</script>
