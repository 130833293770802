<template>
  <teleport v-if="isMounted" to="#tools">
    <div class="flex items-center gap-5 px-4 border-b border-gray-200 h-11">
      <button type="button" class="inline-flex items-center p-1 text-gray-700 border border-transparent rounded-full hover:bg-gray-50 focus:outline-none" @click="reload">
        <RefreshIcon :class="[devicesLoading ? 'animate-reverse-spin' : '', 'h-5 w-5']" aria-hidden="true" />
      </button>
      <template v-if="fullSelection">
        <button type="button" @click="batchEdit('owner')" v-if="isSuperAdmin" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {{ $t("device.action.owner") }}
        </button>
        <Menu as="div" class="relative hidden inline-block text-left">
          <div>
            <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2.5 py-1.5 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <DotsVerticalIcon class="w-4 h-4" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
            <MenuItems class="absolute left-0 w-56 mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                <button @click="batchEdit('alarm')" :class="[
    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
    'block px-4 py-2 text-sm w-full text-left',
  ]">
                  {{ $t("device.action.alarmConfig") }}
                </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <button type="button" @click="showApplyConfigTemplate = true" v-if="isSuperAdmin" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {{ $t("device.action.applyConfigTemplate") }}

        </button>
      </template>
    </div>
  </teleport>
  <div v-if="devicesLoading">
    <h3 class="px-4 py-3 text-sm font-light">{{ $t("device.loading") }}</h3>
    <DeviceListPlaceholder />
  </div>
  <template v-else>
    <TransitionRoot as="template" :show="showSlideIn">
      <Dialog as="div" static class="fixed inset-0 z-20 overflow-hidden" @close="showSlideIn = false" :open="showSlideIn">
        <div class="absolute inset-0 overflow-hidden">
          <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="w-screen max-w-lg">
                <div class="flex flex-col h-full overflow-y-scroll bg-white divide-y divide-gray-200 shadow-xl">
                  <component :is="slideInBatchEditor" @close="showSlideIn = false" :deviceSelection="deviceSelection" />
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="h-full overflow-visible overflow-y-scroll">
      <table class="w-full ">
        <thead class="bg-gray-50">
          <tr>
            <th v-if="isSuperAdmin" scope="col" class="relative w-12 px-4">
              <input type="checkbox" v-model="fullSelection" :class="{ partial: partialSelection }" class="text-pink-500 rounded" />
            </th>

            <th scope="col" class="w-5 px-4 py-2">
              <div class="text-sm font-semibold text-left text-gray-900 group">
                <InformationCircleIcon class="w-4 text-gray-500 group hover:text-cyan-800" aria-hidden="true" />
                <div class="absolute invisible px-3 py-3 mt-2 text-sm font-semibold text-left text-gray-900 truncate origin-top bg-white divide-y divide-gray-100 rounded-md shadow-lg group-hover:visible ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {{ $t("device.header.deviceConnectivityStatus") }}
                </div>
              </div>
            </th>

            <th scope="col" class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
              {{ $t("device.header.serialNumber") }}
            </th>

            <th scope="col" class="px-1 py-3.5 truncate text-left text-sm font-semibold text-gray-900">
              {{ $t("device.header.tenant") }}
            </th>

            <th scope="col" class="px-1 py-3.5 truncate text-left text-sm font-semibold text-gray-900">
              {{ $t("device.header.id") }}
            </th>

            <th scope="col" class="px-1 py-3.5 truncate text-left text-sm font-semibold text-gray-900">
              {{ $t("device.header.braceletColumn") }}
            </th>

            <th scope="col" class="py-3.5	"></th>

            <th scope="col" class="py-3.5 w-8 truncate text-left text-sm font-semibold text-gray-900">
              {{ $t("device.header.version") }}
            </th>
          </tr>
        </thead>

        <tbody class="bg-white divide-y divide-gray-200">
          <tr @click="deviceDetail(device, $event)" v-for="device in devices" :key="device.id" class="cursor-pointer hover:bg-gray-100 hover:shadow-list">
            <td v-if="isSuperAdmin" data-interactive class="w-12 px-4 py-2">
              <input type="checkbox" class="block text-pink-500 rounded" :value="device.id" v-model="deviceSelection" />
            </td>
            <device-list-item :device="device" :isSuperAdmin="isSuperAdmin" />
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  <SimpleAlert @cancel="alertOptions.showAlert = false" @approve="alertOptions.approve(); alertOptions.showAlert = false;" :open="alertOptions.showAlert" :title="alertOptions.title" :description="alertOptions.description" action="Apply">
  </SimpleAlert>
  <ApplyBatchConfigTemplate v-if="isSuperAdmin" :open="showApplyConfigTemplate" @cancel="showApplyConfigTemplate = false" :devices="deviceSelection"></ApplyBatchConfigTemplate>
</template>

<script>
import DeviceListItem from "@/components/DeviceListItem.vue";
import { computed, onMounted, ref, shallowRef } from "vue";
import { useStore } from "vuex";
import { RefreshIcon, InformationCircleIcon } from "@heroicons/vue/outline";
import { DotsVerticalIcon } from "@heroicons/vue/solid";
import { Dialog, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useRouter } from "vue-router";
import DeviceListPlaceholder from "@/components/DeviceListPlaceholder";
import OwnerBatch from "@/components/OwnerBatch";
import SimpleAlert from "@/components/modals/SimpleAlert";
import ApplyBatchConfigTemplate from "@/components/modals/ApplyBatchConfigTemplate.vue";

const batchEditors = {
  owner: OwnerBatch,
};

export default {
  components: {
    DeviceListPlaceholder,
    DeviceListItem,
    DotsVerticalIcon,
    RefreshIcon,
    InformationCircleIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Dialog,
    TransitionChild,
    TransitionRoot,
    SimpleAlert,
    ApplyBatchConfigTemplate
  },
  setup() {
    const showSlideIn = ref(false);
    const slideInBatchEditor = shallowRef(null);
    const store = useStore();
    const router = useRouter();
    const deviceSelection = ref([]);
    const isMounted = ref(false);
    const devices = computed(() => Array.from(store.state.device.all.values()));
    const devicesLoading = computed(() => store.state.device.loading);
    const showApplyConfigTemplate = ref(false);

    const isSuperAdmin = computed(() => {
      return store.getters["auth/isSuperAdmin"];
    });

    const partialSelection = computed(() => {
      return deviceSelection.value.length > 0 && devices.value.length !== deviceSelection.value.length;
    });

    const alertOptions = ref({
      showAlert: false,
      title: "",
      description: "",
      approve: () => {
      },
    });

    const fullSelection = computed({
      get: () => deviceSelection.value.length > 0,
      set: (checked) => (deviceSelection.value = checked ? devices.value.map((device) => device.id) : []),
    });

    const batchEdit = (editor) => {
      slideInBatchEditor.value = batchEditors[editor];
      showSlideIn.value = true;
    };

    onMounted(async () => {
      isMounted.value = true;
      store.dispatch("device/getAggregations");
      await store.dispatch("device/getDevices");
    })

    return {
      isMounted,
      alertOptions,
      deviceDetail(device, event) {
        const target = event.target;
        if (target.tagName.toLowerCase() === "input" || "interactive" in target.dataset) {
          return;
        }
        router.push({ name: "device", params: { serialNumber: device.serialNumber } });
      },
      showSlideIn,
      slideInBatchEditor,
      batchEdit,
      devices,
      devicesLoading,
      deviceSelection,
      partialSelection,
      fullSelection,
      isSuperAdmin,
      showApplyConfigTemplate,
      reload() {
        store.dispatch("device/getAggregations");
        store.dispatch("device/getDevices", true);
      },
    };
  },
};
</script>

<style lang="scss">
[type="checkbox"].partial:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m 4.5,7 h 7 c 0.554,0 1,0.446 1,1 0,0.554 -0.446,1 -1,1 h -7 c -0.554,0 -1,-0.446 -1,-1 0,-0.554 0.446,-1 1,-1 z'/%3e%3c/svg%3e");
}
</style>
