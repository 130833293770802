import { Auth } from "aws-amplify";

export default {
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.VUE_APP_AWS_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },

  Analytics: {
    disabled: true,
  },
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.VUE_APP_OAUTH_DOMAIN,
      redirectSignIn: process.env.VUE_APP_BASE_URL,
      redirectSignOut: process.env.VUE_APP_BASE_URL,
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      responseType: "code",
    },
  },
};
