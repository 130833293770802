<template>

    <div class="h-full mx-auto">
        <div class="px-4 border-b border-gray-200">
            <nav class="flex -mb-px space-x-8">
                <router-link :to="{ name: 'userManagement' }" exact-active-class="device-tab-active" class="px-1 py-2 text-sm font-medium border-b-2 md:py-4 device-tab whitespace-nowrap">
                    {{ $t("userManagement.tab.user") }}
                </router-link>
                <router-link v-if="isSuperAdmin" :to="{ name: 'tenantManagement' }" exact-active-class="device-tab-active" class="px-1 py-2 text-sm font-medium border-b-2 md:py-4 device-tab whitespace-nowrap">
                    {{ $t("userManagement.tab.tenants") }}
                </router-link>
            </nav>
        </div>
        <router-view></router-view>
    </div>

</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
    name: "UserTenantManagement",

    setup() {
        const store = useStore();

        const isSuperAdmin = computed(() => {
            return store.getters["auth/isSuperAdmin"];
        });

        const device = computed(() => store.state.device.current);

        return {
            isSuperAdmin,
            device,
        };
    },
};
</script>

<style lang="scss">
.device-tab {
    color: rgba(107, 114, 128, 1);
    border-color: transparent;

    &:hover {
        color: rgba(55, 65, 81, 1);
        border-color: rgba(209, 213, 219, 1);
    }

    &-active {
        color: rgba(79, 70, 229, 1);
        border-color: rgba(99, 102, 241, 1);

        &:hover {
            color: rgba(79, 70, 229, 1);
            border-color: rgba(99, 102, 241, 1);
        }
    }
}
</style>
