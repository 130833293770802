<template>
  <div class="px-4 m-5 mr-10 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{ $t("device.network.pageTitle") }}</h1>
      </div>
    </div>
    <div class="flex flex-col mt-8">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full">
              <thead class="bg-white">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {{ $t("device.network.header.operator") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("device.network.header.signalQuality") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("device.network.header.networkQuality") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("device.network.header.qualityScore") }}
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">{{ $t("device.network.header.selectNetwork") }}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <template v-for="scan in scans" :key="scan.id">
                  <tr class="border-t border-gray-200">
                    <th colspan="5" scope="colgroup" class="px-4 py-2 text-sm font-semibold text-left text-gray-900 bg-gray-50 sm:px-6">
                      {{ $t("device.network.scan.startedLabel", { type: scan.type, started: scan.started }) }}
                      <span v-show="!scan.finished">{{ $t("device.network.scan.inProgress") }}</span>
                    </th>
                  </tr>
                  <tr v-for="(result, resultIndex) in scan.results" :key="result.operatorCode" :class="[resultIndex === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t']">
                    <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                      {{ result.operatorName }} ({{ result.accessTechnology }})
                    </td>
                    <td class="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div class="text-gray-500" v-if="result.signalLevel">
                        {{ $t("device.network.scan.quality", { signalLevel: result.signalLevel }) }}
                      </div>
                      <div v-if="result.networkMetrics && result.networkMetrics.__typename == 'LteSignalMetrics'">
                        <div class="text-gray-500">
                          {{ $t("device.network.scan.rsrp", { rsrp: result.networkMetrics.RSRP }) }}
                        </div>
                        <div class="text-gray-500">
                          {{ $t("device.network.scan.rsrq", { rsrq: result.networkMetrics.RSRQ }) }}
                        </div>
                      </div>
                      <div v-else-if="result.networkMetrics && result.networkMetrics.__typename == 'GsmSignalMetrics'">
                        <div class="text-gray-500">
                          {{ $t("device.network.scan.rssi", { rssi: result.networkMetrics.RSSI }) }}
                        </div>
                      </div>
                    </td>
                    <td class="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div class="text-gray-500" v-if="result.networkPerformance.uploadSpeed">
                        {{ $t("device.network.performance.uploadSpeed", { uploadSpeed: result.networkPerformance.uploadSpeed }) }}
                      </div>
                      <div class="text-gray-500" v-if="result.networkPerformance.downloadSpeed">
                        {{ $t("device.network.performance.downloadSpeed", { downloadSpeed: result.networkPerformance.downloadSpeed }) }}
                      </div>
                      <div class="text-gray-500" v-if="result.networkPerformance.jitter">
                        {{ $t("device.network.performance.jitter", { jitter: result.networkPerformance.jitter }) }}
                      </div>
                      <div class="text-gray-500" v-if="result.networkPerformance.roundTripTime">
                        {{ $t("device.network.performance.roundTripTime", { roundTripTime: result.networkPerformance.roundTripTime }) }}
                      </div>
                      <div class="text-gray-500" v-if="result.networkPerformance.packetLoss">{{
                        $t("device.network.performance.packetLoss", { packetLoss: result.networkPerformance.packetLoss })
                      }}
                      </div>
                    </td>
                    <td class="px-3 py-4 text-sm text-gray-500 whitespace-nowrap place-items-center">
                      <div class="text-red-600" v-if="result.score == 'UNUSABLE'">
                        <no-signal-icon />
                        {{ $t("device.network.signal.unusable") }}
                      </div>
                      <div class="text-red-500" v-else-if="result.score == 'BAD'">
                        <low-signal-icon />
                        {{ $t("device.network.signal.bad") }}
                      </div>
                      <div class="text-orange-400" v-else-if="result.score == 'OK'">
                        <ok-signal-icon />
                        {{ $t("device.network.signal.ok") }}
                      </div>
                      <div class="text-green-500" v-else-if="result.score == 'GOOD'">
                        <good-signal-icon />
                        {{ $t("device.network.signal.good") }}
                      </div>
                    </td>

                    <td class="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                      <!--<a href="#" class="text-indigo-600 hover:text-indigo-900">Select Network</a>-->
                    </td>
                  </tr>

                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NoSignalIcon, LowSignalIcon, OkSignalIcon, GoodSignalIcon } from "@/components/icons";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "DeviceTabNetwork",
  components: {
    NoSignalIcon,
    LowSignalIcon,
    OkSignalIcon,
    GoodSignalIcon,
  },
  setup() {
    const store = useStore();
    const scans = computed(() => store.state.device.current.networkScans);
    return {
      scans,
    }
  },
  mounted() {
    this.refreshIntervalId = setInterval(async function () {
      const deviceId = this.$store.state.device.current.id;
      await this.$store.dispatch("device/reloadNetworkScans", deviceId);
    }.bind(this), 5000);
  },
  unmounted() {
    clearInterval(this.refreshIntervalId);
  }
};
</script>
