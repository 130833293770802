// https://boxicons.com/
import {createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock} from "vue";

export default function render() {
    return (
        _openBlock(),
            _createBlock(
                "svg",
                {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: 24,
                    height: 24,
                    fill: "currentColor",
                    style: "transform: ;msFilter:;"
                },
                [
                    _createVNode("path", {
                        d: "M3 16h2v5H3z",
                    }),
                ]
            )
    );
}
