import { createI18n } from "vue-i18n";
import messages from "./messages";
import {I18n} from "aws-amplify";
import {translations} from "@aws-amplify/ui-vue";

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: "de" || navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages,
});

I18n.putVocabularies(translations);
I18n.setLanguage('de');
