<template>
  <div class="h-full max-h-full pb-24 m-5 mr-10 overflow-scroll">

    <h1 class="mb-5 text-lg font-medium leading-6 text-gray-900">
      {{ $t("userManagement.tab.editUser") }}
    </h1>

    <div class="w-full mx-auto">

      <div class="flex flex-col w-full mx-auto xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 py-2 whitespace-nowrap">
          {{ $t("userManagement.userTable.email") }}
        </div>
        <div>
          <input type="text" disabled="true" v-model="email" class="w-full text-gray-500 border border-gray-300 rounded-lg sm:text-sm" />
        </div>
      </div>

      <div class="flex flex-col w-full mx-auto mt-5 xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 py-2 whitespace-nowrap">
          {{ $t("userManagement.userTable.name") }}*
        </div>
        <div>
          <input type="text" v-model="name" :class='["w-full text-gray-900 border border-gray-300 rounded-lg sm:text-sm", name && name.length ? "focus:ring-indigo-500 focus:border-indigo-500" : "border-red-500 focus:border-red-600 focus:ring-red-500"]' />
        </div>
      </div>

      <div class="flex flex-col w-full mx-auto mt-5 xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 py-2 whitespace-nowrap">
          {{ $t("userManagement.userTable.phone") }}*
        </div>
        <div>
          <input type="text" @keydown.space="(event) => event.preventDefault()" v-model="phone" :class='["w-full text-gray-900 border border-gray-300 rounded-lg sm:text-sm", isValidPhone() ? "focus:ring-indigo-500 focus:border-indigo-500" : "border-red-500 focus:border-red-600 focus:ring-red-500"]' placeholder="+1234567890123" />
        </div>
      </div>

      <div class="flex flex-col w-full mx-auto mt-5 mb-6 xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 pt-2 whitespace-nowrap">
          {{ $t("userManagement.userTable.tenants") }}
        </div>

        <div v-for="(tenant, index ) in tenants" :key="index" class="flex flex-row gap-2">
          <div class="flex flex-col w-1/2 mx-auto mt-2 ">
            <label for="ownerName" class="block ml-2 text-sm font-medium text-gray-700"> {{ $t("userManagement.userTable.name") }}</label>
            <Combobox as="div" v-model="tenants[index]" :disabled="!isAdmin && !isSuperAdmin">
              <div class="relative mt-2">
                <ComboboxInput :class='["w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-inset sm:text-sm sm:leading-6", tenant?.name ? "ring-gray-300 focus:ring-gray-300 focus:border-gray-300" : "ring-red-500 focus:border-red-600 focus:ring-red-500"]' @change="query = $event.target.value" :display-value="(tenant) => tenant?.name" autocomplete="off" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none" v-if="isAdmin || isSuperAdmin">
                  <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="filteredTenants.length > 0" class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ComboboxOption v-for="selectedTenant in filteredTenants" :key="selectedTenant.id" :value="selectedTenant" as="template" v-slot="{ active, selected }">
                    <li :class="[' relative cursor-default select-none py-1 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ selectedTenant.name }}
                      </span>

                      <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>

          <div class="flex flex-col w-1/2 mx-auto mt-2">
            <label for="ownerName" class="block ml-2 text-sm font-medium text-gray-700"> {{ $t("userManagement.userTable.role") }}</label>
            <Combobox as="div" v-model="roles[index]" :disabled="!isAdmin && !isSuperAdmin">
              <div class="relative mt-2">
                <ComboboxInput :class='["w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-inset sm:text-sm sm:leading-6", roles[index]?.length ? "ring-gray-300 focus:ring-gray-300 focus:border-gray-300" : "ring-red-500 focus:border-red-600 focus:ring-red-500"]' autocomplete="off" />
                <ComboboxButton v-if="isAdmin || isSuperAdmin" class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                  <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="allRoles.length > 0" class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ComboboxOption v-for="selectedRole in allRoles" :key="selectedRole" :value="selectedRole" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-1 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ selectedRole }}
                      </span>

                      <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>

          <div class="flex flex-col mx-auto" v-if="isAdmin || isSuperAdmin">
            <div class="w-full h-full"></div>
            <div class="flex w-16 h-full">
              <button v-if="tenants.length != 1" class="flex items-center w-8 ml-1 text-gray-400" @click="removeTenant(index)">
                <MinusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
              </button>

              <button v-if="index + 1 == tenants.length" class="flex items-center w-8 ml-1 text-gray-400" @click="addTenant">
                <PlusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <div class="flex justify-end flex-shrink-0 py-4 mt-5">
          <button v-if="(isAdmin || isSuperAdmin) && !isCurrentUser" type="button" class="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50" @click="confirmDelete = true">
            {{ $t("common.action.delete") }}
          </button>
          <button v-if="isAdmin || isSuperAdmin" type="button" class="px-4 py-2 ml-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="$emit('cancel')">
            {{ $t("common.action.cancel") }}
          </button>
          <button type="submit" :disabled="!isValid() || !didUserChange()" class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50" @click="confirmUpdate = true">
            {{ $t("common.action.save") }}
          </button>
        </div>

      </div>
    </div>
  </div>

  <SimpleAlert @cancel="confirmDelete = false" @approve="deleteAction" :open="confirmDelete" :title="$t('userManagement.action.deleteUser')" :description="$t('userManagement.action.deleteUserDescription', { email: email })" :action="$t('common.action.apply')" type="error"></SimpleAlert>
  <SimpleAlert @cancel="confirmUpdate = false" @approve="updateAction" :open="confirmUpdate" :title="$t('userManagement.action.updateUser')" :description="$t('userManagement.action.updateUserDescription')" :action="$t('common.action.apply')" type="info"></SimpleAlert>

</template>

<script>
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/vue/outline";
import { computed } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
import SimpleAlert from "@/components/modals/SimpleAlert";
import { useI18n } from "vue-i18n";
import { hasRights, USER_PERMISSIONS } from "@/lib/userHasPermission";

export default {
  name: "EditUser",

  components: {
    MinusCircleIcon,
    PlusCircleIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ChevronDownIcon,
    ComboboxOption,
    ComboboxOptions,
    CheckIcon,
    SimpleAlert
  },


  props: ["user"],
  emits: ["cancel"],

  setup(props, { emit }) {
    const { t } = useI18n();

    const store = useStore();

    const users = computed(() => store.state.users.all);

    const isAdmin = computed(() => hasRights(USER_PERMISSIONS.ADMIN));
    const isViewer = computed(() => hasRights(USER_PERMISSIONS.VIEWER));
    const isSuperAdmin = computed(() => { return store.getters["auth/isSuperAdmin"]; });


    const email = ref(props.user.email)
    const name = ref(props.user.name)
    const phone = ref(props.user.phone)

    const confirmDelete = ref(false);
    const confirmUpdate = ref(false);

    const tenants = ref(
      props.user.tenants && props.user.tenants.length > 0 ?
        props.user.tenants.map((tenant) => ({ id: tenant.tenant.id, name: tenant.tenant.name }))
        :
        [{}]
    )

    const getCurrentUserFromMap = (id) => {
      let key = [...users.value.keys()].find(k => k.includes(id))
      return users.value.get(key).id == props.user.id;
    }

    const isCurrentUser = computed(() => getCurrentUserFromMap(store.state.auth.user.username));


    const roles = ref(props.user.tenants && props.user.tenants.length > 0 ?
      props.user.tenants.map((tenant) => tenant.role)
      :
      [{}])



    const query = ref('')
    const allTenants = computed(() => store.state.tenants);

    const calcfilteredTenants = () => {
      let tmpTenants = allTenants.value;
      if (tenants.value.length > 0) {
        tmpTenants = tmpTenants.filter((t) => !tenants.value.some((tt) => tt.id == t.id))
      }
      if (query.value === '') {
        return tmpTenants
      } else {
        return tmpTenants.filter((owner) => {
          return owner.name.toLowerCase().includes(query.value.toLowerCase())
        })
      }
    }

    const filteredTenants = computed(() => calcfilteredTenants())

    const allRoles = ["ADMIN", "VIEWER"]


    const addTenant = () => {
      tenants.value.push({})
      roles.value.push({})
    }

    const removeTenant = (index) => {
      tenants.value.splice(index, 1);
      roles.value.splice(index, 1);
    }

    const isValidPhone = () => {
      if (!phone.value.startsWith("+")) {
        return false;
      }
      if (phone.value.length < 10 || phone.value.length > 15) {
        return false;
      }

      if (!/^\d+$/.test(phone.value.substring(1)))
        return false;

      return true;
    }

    const isValid = () => {
      let valid = true;

      if (name.value.length == 0 || name.value.trim() == "") {
        return false;
      }

      if (phone.value.length == 0 || name.value.trim() == "") {
        return false;
      }

      if (!phone.value.startsWith("+")) {
        return false;
      }

      if (phone.value.length < 10 || phone.value.length > 15) {
        return false;
      }

      for (let i = 0; i < tenants.value.length; i++) {
        let tenant = tenants.value[i].id;
        let role = roles.value[i] && roles.value[i].length;
        if (!tenant || !role) valid = false;
      }
      return valid;
    }


    const updateAction = async () => {
      let tenantRoles = [];
      let index = 0;
      for (let tenant of tenants.value) {
        tenantRoles.push({ tenantId: tenant.id, role: roles.value[index] })
        index++;
      }

      const oldTenants = props.user.tenants.map((tenant) => ({ tenantId: tenant.tenant.id, name: tenant.tenant.name }));
      const removedTenants = oldTenants.filter((oldTenant) => !tenantRoles.some((tenant) => oldTenant.tenantId == tenant.tenantId))

      const oldTenantRoles = props.user.tenants ? props.user.tenants.map((t) => ({ role: t.role, tenantId: t.tenant.id })) : [];
      const changedTenants = tenantRoles.filter((newTenant) => !oldTenantRoles.some((oldTenant) => oldTenant.tenantId == newTenant.tenantId && oldTenant.role == newTenant.role))

      if (removedTenants && removedTenants.length > 0) {
        await store.dispatch("removeUserFromTenant", {
          userId: props.user.id,
          tenantsIds: removedTenants.map((tenant) => (tenant.tenantId))
        })

        if (changedTenants.length == 0) {
          await store.dispatch("showNotification", {
            title: t("notifications.mutations.userUpdates"),
            type: "info",
          });

          emit("refreshData");
          emit("cancel");
        }
      }
      let ret = await store.dispatch("updateTenantUser", {
        id: props.user.id,
        tenantsRoles: changedTenants.length > 0 ? changedTenants : [],
        name: name.value ? name.value : "",
        phoneNumber: phone.value ? phone.value : "",
      })

      if (ret && ret.tenant.updateUser.ok) {

        await store.dispatch("showNotification", {
          title: t("notifications.mutations.userUpdates"),
          type: "info",
        });

        emit("refreshData");
        emit("cancel");
      } else {
        confirmUpdate.value = false;

        await store.dispatch("showNotification", {
          title: t("notifications.mutations.error"),
          text: t("notifications.mutations.userInvitedError"),
          type: "error",
        });
      }
    }

    const deleteAction = async () => {
      let ret = await store.dispatch("removeTenantUser", {
        userId: props.user.id,
      })

      if (ret && ret.tenant.removeUser.ok) {
        await store.dispatch("showNotification", {
          title: t("notifications.mutations.deleteUser"),
          type: "info",
        });

        confirmDelete.value = false;
        emit("refreshData");
        emit("cancel");

      } else {

        await store.dispatch("showNotification", {
          title: t("notifications.mutations.error"),
          text: t("notifications.mutations.userInvitedError"),
          type: "error",
        });
      }
    }

    const didUserChange = () => {
      let didChange = false;

      if (name.value !== props.user.name) {
        didChange = true;
      }
      if (phone.value !== props.user.phone) {
        didChange = true;
      }

      const localTenants =
        props.user.tenants && props.user.tenants.length > 0 ?
          props.user.tenants.map((tenant) => ({ id: tenant.tenant.id, name: tenant.tenant.name }))
          :
          [{}];

      if (JSON.stringify(tenants.value) != JSON.stringify(localTenants)) {
        didChange = true;
      }

      const localRoles = props.user.tenants && props.user.tenants.length > 0 ?
        props.user.tenants.map((tenant) => tenant.role)
        :
        [{}];

      if (JSON.stringify(roles.value) != JSON.stringify(localRoles)) {
        didChange = true;
      }

      return didChange;
    }

    return {
      email,
      name,
      phone,
      tenants,
      roles,
      allTenants,
      allRoles,
      confirmDelete,
      confirmUpdate,
      addTenant,
      removeTenant,
      updateAction,
      deleteAction,
      isValid,
      isValidPhone,
      isViewer,
      isAdmin,
      isSuperAdmin,
      query,
      filteredTenants,
      isCurrentUser,
      didUserChange
    }
  }
}
</script>
