<template>
    <div>
        <h1 class="mb-2 font-medium text-left text-gray-900">
            {{ $t("device.form.alarmDestinations") }}
        </h1>

        <dd class="mt-1 text-sm text-gray-900">
            <ul class="divide-y divide-gray-200">
                <li class="flex items-center justify-between pt-2 pb-4 pr-4 text-sm divide-gray-200" v-for="(destination, index) in alarmDestinations" :key="index">

                    <div v-if="destination.scaip" class="relative items-center flex-1 w-0 gap-2 lg:flex lg:flex-wrap">
                        <PhoneOutgoingIcon v-if="destination.direction == 'outbound'" class="w-full h-5 mx-auto mb-2 ml-2 text-gray-400 lg:w-5 lg:mb-0 lg:ml-0" />
                        <PhoneIncomingIcon v-else class="w-full h-5 mx-auto mb-2 ml-2 text-gray-400 lg:w-5 lg:mb-0 lg:ml-0" />
                        <div class="flex flex-col items-stretch flex-1 gap-2 ml-2 text-sm">
                            <span class="font-mono text-center break-all lg:text-left">{{ destination.call ? destination.call.callee : '' }}</span>
                            <input :value="destination.scaip ? destination.scaip.controller_id : ''" :placeholder="$t('device.header.id')" type="text" class="w-full h-8 font-mono break-words border-gray-300 rounded-md shadow-sm lg:w-3/4 focus:ring-indigo-500 focus:border-indigo-500" @change="$emit('updateConfig', { index: index, value: $event.target.value })" />
                        </div>
                        <div class="mt-2 font-mono text-sm text-center text-gray-500 lg:mt-0 lg:ml-5 lg:text-right whitespace-nowrap">
                            {{ destination.attempts }} Versuche
                        </div>
                    </div>
                    <div v-else class="flex flex-wrap items-center flex-1 w-0 gap-2">
                        <PhoneOutgoingIcon v-if="destination.direction == 'outbound'" class="w-5 h-5 text-gray-400" />
                        <PhoneIncomingIcon v-else class="w-5 h-5 text-gray-400" />
                        <span class="items-stretch flex-1 ml-2 font-mono text-sm">{{ destination.call ? destination.call.callee : '' }}</span>
                        <div class="font-mono text-sm text-right text-gray-500 whitespace-nowrap">{{ destination.attempts }} Versuche</div>
                    </div>
                </li>
            </ul>
        </dd>
    </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { PhoneIncomingIcon, PhoneOutgoingIcon } from "@heroicons/vue/outline";

export default {
    name: "AlarmDestinationsSettings",
    components: {
        PhoneIncomingIcon,
        PhoneOutgoingIcon,
    },

    props: ["refresh"],
    emits: ["updateConfig"],

    setup(props) {
        const store = useStore();

        const device = computed(() => store.state.device.current);
        const alarmDestinations = computed(() => device.value.configV2.alarm_app.destinations);

        watch(() => props.refresh, () => {
            if (props.refresh) {
                reset()
            }
        })

        watch(device, () => {
            if (device.value)
                reset()
        })

        const reset = () => {
            alarmDestinations.value = device.value.configV2.alarm_app.destinations
        }

        return {
            device,
            alarmDestinations,
        };
    },
};
</script>
