<template>
  <table class="w-full">
    <tbody class="divide-y divide-gray-200 bg-gray-50">
      <tr v-for="row in 5" :key="row" class="animate-pulse">
        <td data-interactive class="w-12 px-4 py-2">
          <div class="w-4 h-4 bg-gray-400 rounded"></div>
        </td>
        <td class="w-5 px-1 py-2">
          <div class="h-2.5 w-2.5 bg-gray-400 rounded-full"></div>
        </td>
        <td class="px-1 py-2 font-medium w-28">
          <div class="w-3/4 h-4 bg-gray-400 rounded"></div>
        </td>
        <td class="px-1 py-2">
          <div class="h-4 pr-12 bg-gray-400 rounded w-72"></div>
        </td>
        <td class="px-1 py-2">
          <div class="w-20 h-4 bg-gray-400 rounded"></div>
        </td>
        <td>
          <div class="h-4 bg-gray-400 rounded-full w-14"></div>
        </td>
        <td class="w-8">
          <div class="w-6 h-6 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-8">
          <div class="w-6 h-6 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-8">
          <div class="w-6 h-6 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-8">
          <div class="w-6 h-6 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-32 pr-4">
          <div class="w-full h-4 bg-gray-400 rounded"></div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "DeviceListPlaceholder",
};
</script>
