import { getAlertLevel, hasAlert } from "./alarms";

const alarmStates = {
  unknown: ["text-gray-800 bg-gray-200"],
  normal: ["text-gray-800 bg-green-200 bg-opacity-50"],
  warning: ["text-gray-800 bg-yellow-100/30 bg-opacity-75"],
  minor: ["text-gray-800 bg-orange-300/30 bg-opacity-50"],
  major: ["text-gray-800 bg-red-400/30 bg-opacity-60"],
  critical: ["text-gray-800 bg-red-400/30"],
  not_applicable: ["bg-gray-100", "text-gray-800"],
};

const statusOffline = ["bg-gray-100", "text-gray-800"];
const statusOk = ["bg-green-100", "text-green-800"];
const statusWarning = ["bg-yellow-100", "text-yellow-800"];
const statusError = ["bg-red-100", "text-red-800"];

const stateColor = (device, alert = "") => {
  if (!device) return statusError;

  const severity = getAlertLevel(alert, device);
  return alarmStates[severity];
};

const dailyCheckInStatusColor = (device) => {
  if (!device.isOnline) {
    return statusOffline;
  }
  if (device.stateSummary.dailyCheckIn === "active") {
    return statusOk;
  }
  if (device.stateSummary.dailyCheckIn === "paused_by_device") {
    return statusWarning;
  }
  if (device.stateSummary.dailyCheckIn === "paused_by_platform") {
    return statusWarning;
  }
  if (device.stateSummary.dailyCheckIn === "missing") {
    return statusError;
  }
  return statusOffline;
};

const braceletPresenceStatusColor = (device, alert = "") => {
  if (alert != "" && hasAlert(alert, device)) {
    const severity = getAlertLevel(alert, device);
    return alarmStates[severity];
  }

  if (!device.isOnline) {
    return statusOffline;
  }

  if (device.stateSummary.braceletBattery === "critical") {
    return statusError;
  }
  if (device.stateSummary.braceletPresence === "in_range") {
    return statusOk;
  }
  if (device.stateSummary.braceletPresence === "out_of_range") {
    return statusError;
  }
  return statusOffline;
};

export { stateColor, braceletPresenceStatusColor, dailyCheckInStatusColor };
