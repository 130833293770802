<template>
  <div class="flex items-center flex-1 w-0">
    <CogIcon v-if="!$props.hideIcon" class="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
    <div class="flex flex-col flex-1 w-0 ml-4">
      <span> {{ $props.caption }}</span>
      <span v-if="$props.subtitle" class="text-gray-400"> {{ $props.subtitle }}</span>
    </div>
    <slot name="tooltip"></slot>
  </div>
  <div class="flex-shrink-0 ml-4">
    <input v-model="enabled" :disabled="$props.disabled" type="checkbox" value="" class="text-indigo-600 transition-colors duration-200 ease-in-out scale-150 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
  </div>
</template>
<script>
import { computed } from "vue";
import { CogIcon } from "@heroicons/vue/outline/esm";

export default {
  props: ["modelValue", "caption", "disabled", "subtitle", "hideIcon"],
  emits: ["update:modelValue"],
  components: {
    CogIcon,

  },

  setup(props, { emit }) {
    const enabled = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      },
    });

    return {
      enabled,
    };
  },
};
</script>
