<template>
    <div>
        <vue-slider v-model="value" v-bind="options" :data="data" :marks="marks"> </vue-slider>
    </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
    name: "DateRangeSlider",
    components: {
        VueSlider,
    },
    emits: ["emitChange"],
    props: ["selection"],
    data: function (props) {
        return {
            value: props.selection,
            options: {
                process: (pos) => [
                    [pos[0], pos[1], { backgroundColor: "rgb(88, 80,236)" }],
                    [pos[2], pos[3], { backgroundColor: "rgb(88, 80,236)" }],
                ],
                enableCross: true,
            },

            // slice first 6 hours (6*4) and last 2hours (2*x)
            data: Array(24 * 4)
                .fill(0)
                .map((_, i) => {
                    return ("0" + ~~(i / 4) + ":0" + 60 * ((i / 4) % 1)).replace(/\d(\d\d)/g, "$1");
                })
                .slice(6 * 4, -3 * 4),
            marks: Array(24)
                .fill(0)
                .map((_, i) => {
                    if (i % 2) return ("0" + i + ":00").replace(/\d(\d\d)/g, "$1");
                })
                .slice(7, -3),
        };
    },
    computed: {
        val1() {
            return this.value[0];
        },
    },
    watch: {
        value: function () {
            this.$emit("emitChange", this.value);
        },
    },
};
</script>

<style>
.vue-slider-dot-tooltip-top,
.vue-slider-dot-tooltip-inner {
    background-color: rgb(88, 80, 236);
    border-color: rgb(88, 80, 236);
    z-index: 40
}
</style>
