import { API, graphqlOperation } from "aws-amplify";
import Store from "@/store";
import i18n from "@/i18n";

export const runQuery = async (query, variables) => {
  try {
    const result = await API.graphql(graphqlOperation(query, variables));
    return result.data;
  } catch ({ errors }) {
    const message = errors && errors.length ? errors[0].message : i18n.global.t("error.unknown");
    await Store.dispatch("showNotification", {
      title: i18n.global.t("error.unknown"),
      text: message,
      type: "error",
    });
  }
};
