import { createApp } from "vue";

import App from "@/App.vue";
import store from "@/store";
import router from "@/router.js";
import i18n from "@/i18n";

import {Amplify} from "aws-amplify";
import awsConfig from "@/aws-exports.js";

import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import hljsVuePlugin from "@highlightjs/vue-plugin";
import 'flowbite';
import { vMaska } from "maska"
import * as Sentry from '@sentry/vue';

hljs.registerLanguage("json", json);

import "./index.css";
import "highlight.js/styles/github.css";

Amplify.configure(awsConfig);

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.VUE_APP_AWS_ENVIRONMENT,
  release: "web-app@0.1.0",
});

app.use(router);
app.use(i18n);
app.use(store);
app.use(hljsVuePlugin);
app.directive("maska", vMaska)
app.mount("#app");
