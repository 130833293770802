<template>
  <h1 v-if="$store.state.device.current.events.edges.length" class="mb-5 text-lg font-medium leading-6 text-gray-900">
    {{ $t("event.header.events") }}</h1>
  <ul class="mb-8">
    <li v-for="event in $store.state.device.current.events.edges.slice(0, 13)" :key="event.node.time">
      <div class="relative pb-4">
        <div class="relative flex space-x-3">
          <div>
            <span :class="[
              getIconBackground(event.node.type),
              'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
            ]">
              <component :is="getIcon(event.node.type)" class="w-5 h-5" aria-hidden="true" />
            </span>
          </div>
          <div class="w-full">
            <Disclosure v-slot="{ open }">
              <DisclosureButton class="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-non e focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span><b>{{ dateFormat(event.node.time) }}: </b> {{ $t(event.node.type) }}</span>
                <ChevronUpIcon :class="open ? 'transform ' : 'rotate-180'" class="w-5 h-5 text-gray-500" />
              </DisclosureButton>
              <DisclosurePanel class="text-sm text-gray-500">
                <highlightjs language="json" :code="formatJson(event.node.data)" />
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { CheckIcon, ChevronUpIcon } from "@heroicons/vue/solid";
import { InformationCircleIcon, ExclamationIcon, UserIcon } from "@heroicons/vue/outline";

const EVENT_TYPE_CATEGORY_MAPPING = {
  "crn::cortex.lifecycle.battery::event-type:critical": "error",
  "crn::cortex.lifecycle.bracelet::event-type:battery_critical": "error",
  "crn::cortex.lifecycle.bracelet::event-type:heartbeat": "info",
  "crn::cortex.lifecycle.bracelet::event-type:out_of_range": "error",
  "crn::cortex.lifecycle.system::event-type:booted": "info",
  "crn::cortex.lifecycle.system::event-type:bottom_button": "user",
  "crn::cortex.lifecycle.system::event-type:shutdown": "info",
  "crn::cortex.lifecycle.system::event-type:reboot": "info",
  "crn::cortex.lifecycle.wall_adapter::event-type:connected": "ok",
  "crn::cortex.lifecycle.wall_adapter::event-type:disconnected": "error",
  "crn::cortex.lifecycle::event-type:arc_call": "user",
  "crn::cortex.lifecycle::event-type:arc_call_v2": "user",
  "crn::cortex.lifecycle.daily_check_in::event-type:activated": "info",
  "crn::cortex.lifecycle.daily_check_in::event-type:deactivated": "info",
  "crn::cortex.lifecycle.daily_check_in::event-type:requested": "info",
  "crn::cortex.lifecycle.daily_check_in::event-type:reminded": "info",
  "crn::cortex.lifecycle.daily_check_in::event-type:done": "ok",
  "crn::cortex.lifecycle.daily_check_in::event-type:missing": "error",
  "crn::cortex.lifecycle.daily_check_in::event-type:suspended_by_platform": "user",
  "crn::cortex.lifecycle.daily_check_in::event-type:resumed_by_platform": "user",
  "crn::cortex.lifecycle.daily_check_in::event-type:suspended_by_device": "user",
  "crn::cortex.lifecycle.daily_check_in::event-type:resumed_by_device": "user",
  "crn::cortex.lifecycle.battery::event-type:fault": "error",
}

export default {
  name: "DeviceAuditLog",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
    CheckIcon,
    ExclamationIcon,
    InformationCircleIcon,
    UserIcon,
  },

  setup() {
    return {
      getIconBackground(event_type) {
        const event_category = EVENT_TYPE_CATEGORY_MAPPING[event_type]
        switch (event_category) {
          case "error":
            return "bg-red-500 text-white";
          case "warning":
            return "bg-orange-300 text-white";
          case "info":
            return "bg-blue-500 text-white";
          case "user":
            return "bg-yellow-100 text-gray-600";
          case "ok":
            return "bg-green-500 text-white";
        }
        return "bg-blue-500";
      },
      getIcon(event_type) {
        const event_category = EVENT_TYPE_CATEGORY_MAPPING[event_type]
        switch (event_category) {
          case "error":
            return ExclamationIcon;
          case "warning":
            return ExclamationIcon;
          case "info":
            return InformationCircleIcon;
          case "user":
            return UserIcon;
          case "ok":
            return CheckIcon;
        }
        return InformationCircleIcon;
      },
      dateFormat(date) {
        return new Date(date).toLocaleString("de-DE");
      },
      formatJson(data) {
        return JSON.stringify(JSON.parse(data), null, 2);
      },
    };
  },
};
</script>
