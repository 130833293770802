import {
  HomeIcon,
  // StatusOfflineIcon,
  // StatusOnlineIcon,
  ChartBarIcon,
  UserIcon,
  CalendarIcon,
  // HeartIcon,
} from "@heroicons/vue/solid";
import * as searchQuery from "search-query-parser";
import i18n from "@/i18n";

const extractUniqueInput = (input, length) => {
  const criteria = input
    .split(" ")
    .filter((e) => e)
    .filter((e) => e.length <= length)
    .map((e) => (e.length < length ? e.concat("*") : e));
  const uniqueCriteria = new Set(criteria);
  return uniqueCriteria.size > 0 ? [...uniqueCriteria] : null;
};

export const filterKeywords = ["is", "has", "status", "owner", "stateFilter", "alarmsFilter"];

export const quickFilters = [
  {
    name: i18n.global.t("filters.all"),
    query: "",
    icon: HomeIcon,
  },
  {
    name: i18n.global.t("alarms.title"),
    icon: HomeIcon,
    subItems: [
      {
        name: i18n.global.t("alarms.critical"),
        color: "red",
        icon: "critical",
        query: "alarmsFilter:critical",
        amount: "-",
      },
      {
        name: i18n.global.t("alarms.major"),
        color: "red",
        icon: "major",
        query: "alarmsFilter:major",
        amount: "-",
      },
      {
        name: i18n.global.t("alarms.minor"),
        color: "orange",
        icon: "minor",
        query: "alarmsFilter:minor",
        amount: "-",
      },
      {
        name: i18n.global.t("alarms.warning"),
        color: "yellow",
        icon: "warning",
        query: "alarmsFilter:warning",
        amount: "-",
      },
      // {
      //   name: i18n.global.t("alarms.normal"),
      //   color: "green",
      //   query: "alarmsFilter:normal",
      //   amount: "-",
      // },
    ],
  },
  {
    name: i18n.global.t("filters.operationStatus"),
    icon: ChartBarIcon,
    subItems: [
      {
        name: i18n.global.t("filters.allOk"),
        color: "green",
        query: "stateFilter:allFine",
        amount: "-",
      },
      {
        name: i18n.global.t("filters.offline"),
        color: "orange",
        query: "stateFilter:offline",
        amount: "-",
      },
      {
        name: i18n.global.t("filters.deviceNotifications"),
        subItems: [
          {
            name: i18n.global.t("filters.off"),
            color: "yellow",
            query: "stateFilter:switchedOff",
            amount: "-",
          },
          {
            name: i18n.global.t("filters.batteryPowered"),
            color: "orange",
            query: "stateFilter:batteryPowered",
            amount: "-",
          },
          {
            name: i18n.global.t("filters.batteryEmpty"),
            color: "red",
            query: "stateFilter:batteryEmpty",
            amount: "-",
          },
        ],
      },
      {
        name: i18n.global.t("filters.braceletNotifications"),
        subItems: [
          {
            name: i18n.global.t("filters.batteryLow"),
            query: "stateFilter:batteryLow",
            color: "orange",
            amount: "-",
          },
          {
            name: i18n.global.t("filters.braceletOutOfRange"),
            query: "stateFilter:braceletOutOfRange",
            color: "red",
            amount: "-",
          },
        ],
      },
    ],
  },

  {
    name: i18n.global.t("filters.dailyCheckIn"),
    icon: CalendarIcon,
    subItems: [
      {
        name: i18n.global.t("filters.dailyCheckInActive"),
        query: "stateFilter:dailyCheckInActive",
        color: "green",
        amount: "-",
        subItems: [
          {
            name: i18n.global.t("filters.dailyCheckInMissing"),
            query: "stateFilter:dailyCheckInMissing",
            color: "red",
            amount: "-",
          },
        ],
      },
      {
        name: i18n.global.t("filters.dailyCheckInPaused"),
        query: "stateFilter:dailyCheckInPaused",
        color: "yellow",
        amount: "-",
        subItems: [
          {
            name: i18n.global.t("filters.dailyCheckInByUser"),
            query: "stateFilter:dailyCheckInByUser",
            color: "yellow",
            amount: "-",
          },
          {
            name: i18n.global.t("filters.dailyCheckInPlatform"),
            query: "stateFilter:dailyCheckInPlatform",
            color: "yellow",
            amount: "-",
          },
        ],
      },
      {
        name: i18n.global.t("filters.dailyCheckInDeactivated"),
        query: "stateFilter:dailyCheckInDeactivated",
        amount: "-",
      },
    ],
  },
];

export const ownerFilter = (owner) => {
  return {
    id: owner.id,
    name: owner.name,
    query: "owner:" + owner.slug,
    icon: UserIcon,
  };
};

export const quickFilterColor = (hash) => {
  const onlineColor = ["text-green-500"];
  const offlineColor = ["text-red-500"];
  const defaultColor = ["text-cyan-200"];

  if (hash === "#offline") {
    return offlineColor;
  } else if (hash === "#online") {
    return onlineColor;
  } else {
    return defaultColor;
  }
};

const extractFilters = (queryData, queryFilter) => {
  const uniqueSerials = extractUniqueInput(queryData, 8);
  const uniqueBraceletsIds = extractUniqueInput(queryData, 12);
  const uniqueSCAIPControllerIds = extractUniqueInput(queryData, 99);
  if (uniqueSerials) {
    queryFilter.serialNumbers = [...uniqueSerials];
  }
  if (uniqueBraceletsIds) {
    queryFilter.braceletIds = [...uniqueBraceletsIds];
  }
  if (uniqueSCAIPControllerIds) {
    queryFilter.SCAIPControllerIds = [...uniqueSCAIPControllerIds];
  }
};

/*
Normalize query data to always be an object containing the "text" property
 */
export const parseQuery = (query, tenants) => {
  const queryData = searchQuery.parse(query, { keywords: filterKeywords, alwaysArray: true });
  let queryFilter = {};

  if (typeof queryData === "string") {
    extractFilters(queryData, queryFilter);
  } else if (queryData !== null && queryData !== undefined) {
    if ("text" in queryData) {
      extractFilters(queryData.text, queryFilter);
    }
    if ("owner" in queryData) {
      let tenantSlugs = queryData.owner;
      const getTenantIdFromSlug = (tenantSlug) => tenants.find((tenant) => tenant.slug === tenantSlug)?.id;
      queryFilter.ownerIds = tenantSlugs.map(getTenantIdFromSlug).filter((tenant) => tenant);
    }
    if ("is" in queryData && queryData.is.length === 1) {
      switch (queryData.is[0]) {
        case "online":
          queryFilter.isOnline = true;
          break;
        case "offline":
          queryFilter.isOnline = false;
          break;
      }
    }
    if ("has" in queryData) {
      if (queryData.has.includes("bracelet")) {
        queryFilter.hasBracelet = true;
      }
    }
    if ("dailyCheck" in queryData) {
      queryFilter.dailyCheckInStates = [...queryData.dailyCheck];
      queryFilter.isOnline = true;
    }
    if ("braceletBattery" in queryData) {
      queryFilter.braceletBatteryStates = [...queryData.braceletBattery];
    }
    if ("braceletPresence" in queryData) {
      queryFilter.braceletPresenceStates = [...queryData.braceletPresence];
    }
    if ("powerSupply" in queryData) {
      queryFilter.powerSupplyStates = [...queryData.powerSupply];
    }
    if ("cloudPresence" in queryData) {
      queryFilter.cloudReportedDevicePresenceStates = [...queryData.cloudPresence];
    }
    if ("devicePresence" in queryData) {
      queryFilter.deviceReportedDevicePresenceStates = [...queryData.devicePresence];
    }
    if ("stateFilter" in queryData) {
      queryFilter.stateFilter = [...queryData.stateFilter];
    }
    if ("alarmsFilter" in queryData) {
      queryFilter.alarmsFilter = [...queryData.alarmsFilter];
    }
  }
  return queryFilter;
};
