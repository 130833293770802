import { queryUsers } from "@/api/users";

const state = () => ({
  all: new Map(),
  current: {},
  loading: false,
});

const getters = {};

const flattenUsers = (users) => {
  if (users.edges) {
    return users.edges.map((edge) => {
      let user = edge.node;
      user.tenants = user.tenants.edges.map((edge) => edge.node);
      return user;
    });
  }
  return [];
};

const actions = {
  async getUsers({ state, commit }, refresh = false) {
    if (state.all.size === 0 || refresh) {
      commit("setLoading", true);
      let hasNextPage = true;
      let nextPageToken = null;
      let users = [];
      while (hasNextPage) {
        const result = await queryUsers(nextPageToken);
        hasNextPage = result.hasNextPage;
        nextPageToken = result.nextPageToken;
        users = users.concat(flattenUsers(result.users));
        if (users.length > 0) commit("setUsers", users);
        commit("setLoading", false);
      }
    }
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setUsers(state, users) {
    state.all.clear();
    users.forEach((user) => {
      state.all.set(user.id, user);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
