<template>
  <div class="h-full max-h-full pb-24 m-5 mr-10 overflow-scroll">

    <h1 class="mb-5 text-lg font-medium leading-6 text-gray-900">
      {{ $t("userManagement.tab.inviteUser") }}
    </h1>

    <div class="w-full mx-auto">

      <div class="flex flex-col w-full mx-auto xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 py-2 whitespace-nowrap">
          {{ $t("userManagement.userTable.email") }}{{ " " }} *
        </div>
        <div>
          <input type="text" v-model="email" @change="validateEmail" @keyup="validateEmail" :class='["w-full text-gray-900 border border-gray-300 rounded-lg sm:text-sm", isEmailValid ? "focus:ring-indigo-500 focus:border-indigo-500" : "border-red-500 focus:border-red-600 focus:ring-red-50"]' :placeholder="$t('userManagement.userTable.email')" />
        </div>
      </div>

      <div class="flex flex-col w-full mx-auto mt-5 mb-6 xl:w-1/2 lg:w-2/3">
        <div class="w-full px-2 pt-2 whitespace-nowrap">
          {{ $t("userManagement.userTable.tenants") }}{{ " " }} *
        </div>

        <div v-for="(tenant, index ) in tenants" :key="index" class="flex flex-row gap-2">
          <div class="flex flex-col w-1/2 mx-auto mt-2 ">
            <label for="ownerName" class="block ml-2 text-sm font-medium text-gray-700"> {{ $t("userManagement.userTable.name") }}</label>
            <Combobox as="div" v-model="tenants[index]">
              <div class="relative mt-2">
                <ComboboxInput :class='["w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-inset sm:text-sm sm:leading-6", tenant?.name ? "ring-gray-300 focus:ring-gray-300 focus:border-gray-300" : "ring-red-500 focus:border-red-600 focus:ring-red-500"]' @change="query = $event.target.value" :display-value="(tenant) => tenant?.name" autocomplete="off" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                  <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="filteredTenants.length > 0" class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ComboboxOption v-for="selectedTenant in filteredTenants" :key="selectedTenant.id" :value="selectedTenant" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-1 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ selectedTenant.name }}
                      </span>

                      <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>

          <div class="flex flex-col w-1/2 mx-auto mt-2">
            <label for="ownerName" class="block ml-2 text-sm font-medium text-gray-700"> {{ $t("userManagement.userTable.role") }}</label>
            <Combobox as="div" v-model="roles[index]">
              <div class="relative mt-2">
                <ComboboxInput :class='["w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-inset sm:text-sm sm:leading-6", roles[index]?.length ? "ring-gray-300 focus:ring-gray-300 focus:border-gray-300" : "ring-red-500 focus:border-red-600 focus:ring-red-500"]' autocomplete="off" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                  <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="allRoles.length > 0" class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ComboboxOption v-for="selectedRole in allRoles" :key="selectedRole" :value="selectedRole" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-1 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ selectedRole }}
                      </span>

                      <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>

          <div class="flex flex-col mx-auto">
            <div class="w-full h-full"></div>
            <div class="flex w-16 h-full">
              <button v-if="tenants.length != 1" class="flex items-center w-8 ml-1 text-gray-400" @click="removeTenant(index)">
                <MinusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
              </button>

              <button v-if="index + 1 == tenants.length" class="flex items-center w-8 ml-1 text-gray-400" @click="addTenant">
                <PlusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>


        <div class="flex justify-end flex-shrink-0 py-4 mt-5">
          <button type="button" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="$emit('cancel')">
            {{ $t("common.action.cancel") }}
          </button>
          <button type="submit" :disabled="!isValid()" class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50" @click="confirmCreate = true">
            {{ $t("common.action.save") }}
          </button>
        </div>

      </div>
    </div>
  </div>

  <SimpleAlert @cancel="confirmCreate = false" @approve="save" :open="confirmCreate" :title="$t('userManagement.action.inviteUser')" :description="$t('userManagement.action.inviteUserDescription')" :action="$t('common.action.apply')" type="info"></SimpleAlert>
</template>

<script>
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/vue/outline";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import SimpleAlert from "@/components/modals/SimpleAlert";
import { useI18n } from "vue-i18n";

export default {
  name: "InviteUser",

  components: {
    MinusCircleIcon,
    PlusCircleIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ChevronDownIcon,
    ComboboxOption,
    ComboboxOptions,
    CheckIcon,
    SimpleAlert
  },

  setup(props, { emit }) {
    const { t } = useI18n();

    const store = useStore();

    const email = ref("")
    const isEmailValid = ref(false)
    const tenants = ref([{}])
    const roles = ref([{}])
    const confirmCreate = ref(false);

    const query = ref('')
    const allTenants = computed(() => store.state.tenants);

    const calcfilteredTenants = () => {
      let tmpTenants = allTenants.value;

      if (tenants.value.length > 0) {
        tmpTenants = tmpTenants.filter((t) => !tenants.value.some((tt) => tt.id == t.id))
      }
      if (query.value === '') {
        return tmpTenants
      } else {
        return tmpTenants.filter((owner) => {
          return owner.name.toLowerCase().includes(query.value.toLowerCase())
        })
      }
    }

    const filteredTenants = computed(() => calcfilteredTenants())


    const allRoles = ["ADMIN", "VIEWER"]

    const validateEmail = () => {
      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isEmailValid.value = emailReg.test(email.value);
      return emailReg.test(email.value)
    }

    const addTenant = () => {
      tenants.value.push({})
      roles.value.push({})
    }

    const removeTenant = (index) => {
      tenants.value.splice(index, 1);
      roles.value.splice(index, 1);
    }

    const isValid = () => {
      let valid = false;
      if (isEmailValid.value) {
        valid = true;
      }

      for (let i = 0; i < tenants.value.length; i++) {
        let tenant = tenants.value[i].id;
        let role = roles.value[i] && roles.value[i].length;
        if (!tenant || !role) valid = false;
      }
      return valid;
    }

    const save = async () => {

      let tenantRoles = [];
      let index = 0;
      for (let tenant of tenants.value) {
        tenantRoles.push({ tenantId: tenant.id, role: roles.value[index] })
        index++;
      }

      let ret = await store.dispatch("inviteTenantUser", {
        email: email.value,
        tenantsRoles: tenantRoles
      });

      if (ret && ret.tenant && ret.tenant.inviteUser.ok) {
        email.value = "";
        isEmailValid.value = false;
        tenants.value = [{}];
        roles.value = [{}]

        await store.dispatch("showNotification", {
          title: t("notifications.mutations.userInvited"),
          text: t("notifications.mutations.userInvitedDescription"),
          type: "info",
        });

        confirmCreate.value = false;

        emit("refreshData");
        emit("cancel");

      } else {

        await store.dispatch("showNotification", {
          title: t("notifications.mutations.error"),
          text: t("notifications.mutations.userInvitedError"),
          type: "error",
        });
      }
    }

    return {
      email,
      tenants,
      roles,
      validateEmail,
      isEmailValid,
      addTenant,
      removeTenant,
      allTenants,
      allRoles,
      save,
      isValid,
      confirmCreate,
      query,
      filteredTenants
    }
  },

};
</script>
