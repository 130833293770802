import userQuery from "./graphql/users_query.graphql";
import { runQuery } from "@/api/query";

export const queryUsers = async (nextPageToken = null) => {
  const result = await runQuery(userQuery, {
    nextPageToken,
  });

  const users = result ? result.users : [];
  const hasNextPage = result ? result.users.pageInfo.hasNextPage : false;
  const newNextPageToken = result ? result.users.pageInfo.endCursor : null;
  return { users, hasNextPage, nextPageToken: newNextPageToken };
};
