<template>
  <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="25" height="27">
    <path
      d="M15 2.321c0-1.67-2.02-2.506-3.2-1.325L6.175 6.621H3.76c-1.426 0-2.897.83-3.325 2.381A12.2 12.2 0 0 0 0 12.246c0 1.123.151 2.21.438 3.244.426 1.55 1.897 2.381 3.323 2.381h2.413l5.625 5.625C12.98 24.677 15 23.841 15 22.171V2.321Z" />
    <rect width="12" height="2.25" x="18" y="10.943" rx="1.125" />
    <rect width="10.505" height="2.25" x="17.25" y="7.428" rx="1.125" transform="rotate(-45 17.25 7.428)" />
    <rect width="10.5" height="2.25" x="24.675" y="25.209" rx="1.125" transform="rotate(-135 24.675 25.209)" />
  </svg>


</template>

<script>
export default {
  name: 'VolumeLoudIcon',
}
</script>
