import tenantsQuery from "./graphql/tenants_query.graphql";
import tenantCreate from "./graphql/tenant_create.graphql";
import tenantInviteUser from "./graphql/tenant_invite_user.graphql";
import tenantUpdateUser from "./graphql/tenant_update_user.graphql";
import tenantRemoveUser from "./graphql/tenant_remove_user.graphql";
import tenantRemoveUserFromTenant from "./graphql/tenant_remove_from_user.graphql";

import { runQuery } from "@/api/query";

export const queryTenants = async (nextPageToken = null) => {
  const result = await runQuery(tenantsQuery, { nextPageToken });
  const tenants = result ? result.tenants.edges.map((edge) => edge.node) : [];
  const hasNextPage = result ? result.tenants.pageInfo.hasNextPage : false;
  const newNextPageToken = result ? result.tenants.pageInfo.endCursor : null;
  return { tenants, hasNextPage, nextPageToken: newNextPageToken };
};

export const createTenant = async (tenantName) => {
  return await runQuery(tenantCreate, { name: tenantName });
};

export const inviteUser = async (data) => {
  return await runQuery(tenantInviteUser, {
    ...data,
  });
};

export const updateUser = async (data) => {
  return await runQuery(tenantUpdateUser, {
    ...data,
  });
};

export const removeUser = async (data) => {
  return await runQuery(tenantRemoveUser, {
    ...data,
  });
};

export const removeUserTenant = async (data) => {
  return await runQuery(tenantRemoveUserFromTenant, {
    ...data,
  });
};
