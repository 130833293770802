<template>
  <svg width="700pt" height="700pt" viewBox="0 0 700 700" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,129.71,69.9974)">
        <path d="M358.546,378.009L359.383,201.601C359.836,165.362 331.002,141.133 312.83,133.461L300.49,45.488C297.982,27.629 282.482,14 264.443,14L175.759,14C157.728,14 142.228,27.836 139.712,45.695L127.349,133.054C99.79,144.105 81.193,170.659 81.193,203.077L81.193,356.927C81.193,390.751 100.994,420.595 128.252,433.251L139.654,514.31C142.17,532.169 157.724,546.005 175.756,546.005L264.444,546.005C282.475,546.005 297.975,532.173 300.491,514.31L311.753,434.388C334.991,424.662 352.819,403.208 358.546,378.009ZM170.62,50.43C170.983,47.879 173.194,44.801 175.765,44.801L264.453,44.801C267.023,44.801 269.23,47.879 269.593,50.43L280.441,128.801L159.771,128.801L170.62,50.43ZM269.593,510.11C269.23,512.661 267.019,515.196 264.449,515.196L175.765,515.196C173.198,515.196 170.987,512.661 170.624,510.11L160.792,439.598L279.422,439.598L269.593,510.11ZM283.148,408.8L157.068,408.8C155.67,408.8 111.998,402.476 111.998,356.929L111.998,203.079C111.998,177.431 131.318,159.602 157.068,159.602L283.158,159.602C308.782,161.216 326.853,176.227 326.918,201.601L325.893,378.002C316.194,405.998 284.498,408.799 283.151,408.799L283.148,408.8Z" style="fill-rule:nonzero;"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BraceletIcon',
}
</script>

<style scoped>
</style>
